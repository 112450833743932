import { Box, Button, Container, Dialog, Drawer, Grid, Hidden, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { BackendRoutes, Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
import { TermsAndCondition } from "./component/termsAndCondition";
import { Close } from "@mui/icons-material";
import { welcomePageUseStyles } from "./style";
import { initital_screen_state } from "../../utils/login/utils";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#7EA1FF",
    backgroundImage: `url(/images/welcome.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    [theme.breakpoints.down(322)]: {
      backgroundPosition: "bottom"

    },
    position: "relative"

  },
  welcomeText: {
    fontSize: "1.625rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: "white",
  },
  img: {
    width: "auto",
    height: "50px",
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    padding: "16px",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      color: "#5078E1",
      backgroundColor: "#FFFFFF",
    },
  },
  buttonTextStyle: {
    fontSize: "0.875rem",
    color: "#5078E1",
    fontFamily: FontFamilySwitch().bold,
  },
  termsAndConditionText: {
    fontSize: "0.75rem",
    color: "#fff",
    fontFamily: FontFamilySwitch().regular,
    cursor: "pointer"
  },
  termsAndConditionUnderLineText: {
    fontSize: "0.75rem",
    color: "#fff",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer"
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  getStartBtn: {
    height: 48,
    fontFamily: FontFamilySwitch().bold
  },
  btnBox: {
    padding: "16px",
    borderTop: `1px solid ${theme.palette.border.secondary}`
  }
}));

const WelcomePage = ({ t = () => false }) => {
  let temp_login_screen_state = localStorage?.getItem(LocalStorageKeys?.login_screen_state);
  let login_screen_state = JSON?.parse(temp_login_screen_state);
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const classes = useStyles();
  const classes_1 = welcomePageUseStyles({ size });
  const history = useHistory();
  const search = useLocation().search;
  const welcomePageAuthToken = new URLSearchParams(search).get("token");
  const [decoded, setDecoded] = React.useState(null);
  const [tcStatus, setTCStatus] = React.useState(false)
  const [termCondition, setTermCondition] = React.useState("")
  const [is_loading, set_is_loading] = useState(true);
  const [screen_state, set_screen_state] = useState(login_screen_state ?? initital_screen_state);
  React.useEffect(() => {

    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      setDecoded(jwt_decode(welcomePageAuthToken));
      getTermsAndCondition(jwt_decode(welcomePageAuthToken))
      initialLoad();
    }
    // eslint-disable-next-line
  }, []);

  const initialLoad = () => {
    if (login_screen_state) {
      if (window.location.origin === login_screen_state?.domain) {
        set_is_loading(false);
      } else {
        getInitialRender();
      }
    } else {
      getInitialRender();
    }
  }

  const getInitialRender = () => {
    NetworkCall(
      `${config?.auth_api_url}${BackendRoutes?.login_domain_initial_render}`,
      NetWorkCallMethods?.post, {}, null, false, false)
      .then((res) => {
        let is_custom_domain = res?.data?.data?.is_custom_domain;
        let _ = res?.data?.data?.render_data?.[0]
        if (_) {
          let temp_screen_state = {
            ...screen_state,
            [_?.id && "id"]: _?.id,
            [_?.region && "region"]: _?.region,
            [_?.login_logo && "logo"]: _?.login_logo,
            [_?.web_background && "web_background"]: _?.web_background,
            [_?.mobile_background && "mob_background"]: _?.mobile_background,
            [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
            [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
            [_?.flag && "flag"]: _?.flag,
            [_?.domain && "domain"]: _?.domain,
            [is_custom_domain && "is_custom_domain"]: is_custom_domain,
          }
          set_screen_state(temp_screen_state);
          localStorage.setItem(LocalStorageKeys?.login_screen_state, JSON?.stringify(temp_screen_state));
        }
        set_is_loading(false);
      }).catch((error) => {
        alert.setSnack({
          ...alert, open: true,
          severity: AlertProps.severity.error,
          msg: t("Some Thing Went Wrong"),
        });
        set_is_loading(false);
      });
  }
  const getStarted = () => {

    const payload = {
      userId: decoded.user_id,
    };
    NetworkCall(
      `${config.auth_api_url}/auth/verifyUser/?token=${welcomePageAuthToken}`,
      NetWorkCallMethods.post,
      payload,
      { "Referer": "https://resident.pms2.propgoto.com/" },
      false,
      false
    )
      .then((response) => {
        if (response?.data?.is_allowed) {
          history.push(
            Routes.setupAccount + "?welcomeAuthToken=" + welcomePageAuthToken
          );
        } else {
          history.push(Routes.login);
        }
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  const getTermsAndCondition = (decoded_data = decoded) => {

    const payload = {
      client: decoded_data?.client,
    };
    NetworkCall(
      `${config.auth_api_url}/client/terms_and_conditions`,
      NetWorkCallMethods.post,
      payload,
      { "Referer": "https://resident.pms2.propgoto.com/" },
      false,
      false
    )
      .then((response) => {
        // if (response?.data?.is_allowed) {
        //   history.push(
        //     Routes.setupAccount + "?welcomeAuthToken=" + welcomePageAuthToken
        //   );
        // } else {
        //   history.push(Routes.login);
        // }
        setTermCondition(response?.data?.data)
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  const handleClose = () => {
    setTCStatus(false)
  }

  const logIn = () => {
    history.push({
      pathname: Routes.login,
      state: {
        origin: Routes?.welcomePage
      },
    });
  }

  return (
    is_loading
      ? <></>
      : <Container className={classes_1.mob_right_section_card} maxWidth="sm"
        sx={{
          backgroundImage: `url(${screen_state?.mob_welcome_background})`,
        }}>
        <Grid container className={classes_1.mob_content}>
          <Grid item xs={12} alignSelf={"self-end"} className={classes_1.mob_items}>
            <Stack direction={"column"} alignItems={"start"}>
              <img className={classes_1.mob_logo} src={screen_state?.logo} alt={t("logo")} />
              <Box height={"20px"} />
              <Typography className={classes_1.mob_description}>
                {t("Easy, secure & most reliable solution for property & facility management")}
              </Typography>
              <Box height={"40px"} />
              <Button className={classes_1.mob_set_up_new_button} onClick={getStarted}>
                <Typography className={classes_1.mob_set_up_new_button_text}>
                  {t("Set Up New Account")}
                </Typography>
              </Button>
              <Box height={"16px"} />
              <Button className={classes_1.mob_log_in_button} onClick={logIn}>
                <Typography className={classes_1.mob_log_in_button_text}>
                  {t("Log In")}
                </Typography>
              </Button>
              <Box height={"20px"} />
              <Typography className={classes_1.mob_terms_of_use_description} textAlign={"center"}>
                {t(`By tapping "Set Up New Account", you agree to our `)}
                <Typography className={classes_1.mob_terms_of_use_word}
                  onClick={() => setTCStatus(true)}>
                  {t("Terms & Conditions")}
                </Typography>
              </Typography>
            </Stack>
          </Grid>
        </Grid>


        <Hidden smDown>
          <Dialog
            className={classes.dialog}
            maxWidth="sm"
            fullWidth
            open={tcStatus}
            onClose={() => handleClose()}
          >
            <Grid container>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }} p={2}>
                <Close sx={{ color: "#4E5A6B" }} onClick={() => handleClose()} />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ height: "450px", overflow: "auto" }} p={2}>
                  <TermsAndCondition termsCondition={termCondition} t={t} />
                </Box>
                <Box className={classes.btnBox}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.getStartBtn}
                    onClick={getStarted}
                  >
                    {t("GetStarted")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Dialog>
        </Hidden>
        <Hidden smUp>
          <Drawer
            anchor="bottom"
            className={classes.drawer}
            open={tcStatus}
            onClose={() => handleClose()}
          >
            <Grid container>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }} p={2}>
                <Close sx={{ color: "#4E5A6B" }} onClick={() => handleClose()} />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ height: "450px", overflow: "auto" }} p={2}>
                  <TermsAndCondition termsCondition={termCondition} t={t} />
                </Box>
                <Box className={classes.btnBox}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.getStartBtn}
                    onClick={getStarted}
                  >
                    {t("GetStarted")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Drawer>
        </Hidden>
      </Container>
  );
};
export default withNamespaces("welcomePage")(WelcomePage)
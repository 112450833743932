import React from "react";
import Box from "@mui/material/Box";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { LoderSimmer } from "../../components";
import { Routes } from "../../router/routes";
import { useStyles } from "./styles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import AddIcon from "@mui/icons-material/Add";
import {
  Avatar,
  AvatarGroup,
  Button,
  Container,
  Divider,
  Fab,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { DialogDrawer } from "../../components";
import AgreementList from "./components/AgreementList";
import { NetworkCall } from "../../networkcall";
import { AlertProps, generateImageUrl, img_size, NetWorkCallMethods } from "../../utils";
import { config } from "../../config";
import InfiniteScroll from "react-infinite-scroll-component";
import { AlertContext } from "../../contexts";
import NoDataFound from "../../components/noDataFound/noDataFound";
import ImageAndPdfViewer from "../../components/assetViewer/imageAndPdfViewer";
const OccupantsList = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const size = useWindowDimensions();
  let fullSize = size?.height - 55;
  const { t = () => false, handleTopBarState } = props;
  const [drawer, setDrawer] = React.useState(false);
  const [openList, setopenList] = React.useState(false);
  const [currentAgreement, setCurrentAgreement] = React.useState({});
  const [occupantDetails, setOccupantDetails] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const { state } = useLocation();
  const [data, setData] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const [agreementList, setAgreementList] = React.useState();
  const alert = React.useContext(AlertContext);
  const [imageViewer, setImageViewer] = React.useState(false);
  const [profileImageViewer, setPofileImageViewer] = React.useState(false);

  if (!state) {
    history.replace(Routes.agreement);
  }
  React.useEffect(() => {
    handleTopBarState({
      text: t("Occupants"),
      customBackpath: Routes.agreement
    })
    setLoading(true);
    setAgreementList(state?.main?.agreement?.agreement_unit);
    if (state?.main?.selected_agreement) {
      setCurrentAgreement(state?.main?.selected_agreement);
      getOccupantsList(state?.main?.selected_agreement?.unit_id, 0);
    } else {
      setCurrentAgreement(state?.main?.agreement?.agreement_unit[0]);
      getOccupantsList(state?.main?.agreement?.agreement_unit[0]?.unit_id, 0);
    }
    
    // eslint-disable-next-line
  }, []);

  const getOccupantsList = (unit_id, offset, fetchMore = false) => {
    const data = {
      unit_id: unit_id,
      limit: 20,
      offset: offset,
    };
    NetworkCall(
      `${config?.api_url}/occupants/list`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (fetchMore) {
          setData((res) => [...res, ...response?.data?.data]);
        } else {
          setData(response?.data?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  const fetchMoreData = () => {
    const tempOffset = offset + 20;
    setOffset(tempOffset);
    getOccupantsList(currentAgreement?.unit_id, tempOffset, true);
  };
  const openAddOccupants = () => {
    setDrawer(true);
  };
  const onSelectAgreement = (res) => {
    setOffset(0);
    setopenList(false);
    setCurrentAgreement(res);
    setLoading(true);
    getOccupantsList(res?.unit_id, 0);
  };
  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TitleBar text={t("Occupants")} goBack={goBack} /> */}

      <div
        className={classes.mainPadding}
        style={{ height: fullSize, overflow: "auto" }}
      >
        <Box className={classes.unitNameContainer}>
          <Box flexGrow={1}>
            <Typography className={classes.unitTitle}>
              {currentAgreement?.name}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setopenList(true)}>
              <KeyboardArrowDownIcon color="#091B29" />
            </IconButton>
          </Box>
        </Box>
        <Box height={"16px"} />
        <Typography className={classes.title}>
          {t("Occupants")} ({data?.length})
        </Typography>
        <Box height={"13px"} />
        {loading ? (
          <Box p={2}>
            <LoderSimmer count={6} />
          </Box>
        ) : (
          <Box className={classes.listContainer}>
            {data?.length > 0 ? (
              <>
                <InfiniteScroll
                  dataLength={data.length}
                  next={fetchMoreData}
                  hasMore={true}
                  height={size?.height - 175}
                >
                  <Box height={"16px"} />
                  <Stack
                    direction={"column"}
                    divider={<Divider sx={{ borderColor: "#E4E8EE" }} />}
                  >
                    {data?.map((data, index) => (
                      <OccupantsListCard
                        key={index}
                        data={data}
                        setOccupantDetails={setOccupantDetails}
                        openDrawer={openAddOccupants}
                      />
                    ))}
                  </Stack>
                  <Box height={"16px"} />
                </InfiniteScroll>
              </>
            ) : (
              <NoDataFound
                height={size?.height - 210}
                content={t("No Data Found!")}
              />
            )}
          </Box>
        )}
      </div>
      <Grid container>
        <Grid item xs={12} style={{ position: "relative" }}>
          <Box sx={{ display: "flex", float: "right" }}>
            <Box>
              <Fab
                color="primary"
                className={classes.fab}
                aria-label="add"
                onClick={() =>
                  history.push({
                    pathname: Routes.addOccupants,
                    state: {
                      agreement: currentAgreement
                    },
                  })
                }
              >
                <AddIcon />
              </Fab>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <DialogDrawer
        open={drawer}
        onClose={() => setDrawer(false)}
        onOpen={() => setDrawer(true)}
        isnotTitle={false}
        header={t("Occupants Details")}
        component={
          <EditOccupants
            t={t}
            data={occupantDetails}
            currentAgreement={currentAgreement}
            setImageViewer={setImageViewer}
            setPofileImageViewer={setPofileImageViewer}
          />
        }
      />
      <DialogDrawer
        open={openList}
        onClose={() => setopenList(false)}
        onOpen={() => setopenList(true)}
        isnotTitle={false}
        header={t("Agreement")}
        component={
          <AgreementList
            t={t}
            list={agreementList}
            data={currentAgreement}
            onSelectAgreement={onSelectAgreement}
          />
        }
      />
      <DialogDrawer
        open={imageViewer}
        onClose={() => setImageViewer(false)}
        isnotTitle={true}
        padding={0}
        overflow={"overlay"}
        component={
          <ImageAndPdfViewer
            title={t("Identification")}
            assets={occupantDetails?.identification_assets?.map((val) => {
              return {
                meta_data: {
                  title: "Identification",
                  file_type: "image",
                },
                file_url: val?.file_url,
              };
            })}
            onClose={() => setImageViewer(false)}
          />
        }
      />
      <DialogDrawer
        open={profileImageViewer}
        onClose={() => setPofileImageViewer(false)}
        isnotTitle={true}
        padding={0}
        overflow={"overlay"}
        component={
          <ImageAndPdfViewer
            assets={occupantDetails?.profile_picture}
            onClose={() => setPofileImageViewer(false)}
          />
        }
      />
    </Container>
  );
};
const OccupantsListCard = ({ data, openDrawer, setOccupantDetails }) => {
  const classes = useStyles();
  const language = localStorage.getItem("i18nextLng");
  const open = (data) => {
    setOccupantDetails(data);
    openDrawer(true);
  };
  return (
    <Box className={classes.cardContainer} onClick={() => open(data)}>
      <Box>
        <img
          className={classes.cardImg}
          src={
            data?.profile_picture
              ? generateImageUrl(data?.profile_picture, img_size.small_square)
              : "/images/profileBlue.svg"
          }
          alt=""
        />
      </Box>
      <Box flexGrow={1} alignSelf="flex-start">
        <Stack direction={"row"}>
          <Typography className={classes.cardTitle}>
            {data?.occupant_name}{" "}
          </Typography>
          {data?.relationship &&
            <Typography className={classes.type}>{data?.relationship}</Typography>
          }
        </Stack>
        <Typography className={classes.cardSubTitle}>
          {data?.gender} <div className={classes.dot} />{" "}
          {data?.identification_proof_no}
        </Typography>
      </Box>
      <Box>
        <IconButton className={classes.iconButton}>
          {language === "ar" ?
            <KeyboardArrowLeftIcon fontSize="small" /> :
            <KeyboardArrowRightIcon fontSize="small" />
          }
        </IconButton>
      </Box>
    </Box>
  );
};
const EditOccupants = ({ data, currentAgreement, t, setImageViewer, setPofileImageViewer }) => {
  const classes = useStyles();
  const history = useHistory();
  const [notesSeeMore, setnotesSeeMore] = React.useState(false);
  const noteTestLimit = 50;
  return (
    <>
      <Box
        className={classes.editContainer}
        sx={{ paddingLeft: "10px !important" }}
      >
        <div className={classes.contentGrp}>
          <Box display={"flex"}>
            <Box flexGrow={1}>
              <Typography className={classes.label}>
                {t("Occupants Details")}
              </Typography>
              <Stack direction={"row"}>
                <Typography className={classes.cardTitle}>
                  {data?.occupant_name}
                </Typography>
                {data?.relationship &&
                  <Typography className={classes.type}>{data?.relationship}</Typography>
                }
              </Stack>
            </Box>
            <Box onClick={data?.profile_picture ? () => setPofileImageViewer(true) : ""}>
              <img
                className={classes.cardImg}
                src={
                  data?.profile_picture
                    ? generateImageUrl(data?.profile_picture, img_size.small_square)
                    : "/images/profileBlue.svg"
                }
                alt=""
              />
            </Box>
          </Box>
        </div>
        <div className={classes.contentGrp}>
          <Typography className={classes.label}>{t("Gender")}</Typography>
          <Typography className={classes.cardTitle}>{data?.gender}</Typography>
        </div>
        <div className={classes.contentGrp}>
          <Typography className={classes.note}>
            <Typography className={classes.label} component="span">
              {t("Note: ")}
            </Typography>
            {data?.notes?.length >= noteTestLimit
              ? notesSeeMore
                ? data?.notes
                : data?.notes?.substring(0, noteTestLimit) + "..."
              : data?.notes}
            {data?.notes?.length >= noteTestLimit && (
              <Typography
                className={classes.seeMore}
                component="h6"
                align="right"
              >
                <span onClick={() => setnotesSeeMore(!notesSeeMore)}>{notesSeeMore ? "See less" : "See more"}</span>
              </Typography>
            )}
          </Typography>
        </div>
        <div className={classes.contentGrp}>
          <Box display={"flex"}>
            <Box flexGrow={1}>
              <Typography className={classes.label}>
                {t("ID Proof Details")}
              </Typography>
              <Typography className={classes.cardTitle}>
                {data?.identification_proof_no}
              </Typography>
              <Typography className={classes.label}>
                {data?.proof_type}
              </Typography>
            </Box>
            <Box className={classes.imgGrp}>
              <AvatarGroup max={4} onClick={() => setImageViewer(true)}>
                {data?.identification_assets?.map((res, index) => (
                  <Avatar
                    key={index}
                    alt={index}
                    className={classes.cardImgGrp}
                    src={generateImageUrl(res?.file_url, img_size.small_square)}
                  />
                ))}
              </AvatarGroup>
            </Box>
          </Box>
        </div>
        <Box height={"16px"} />
        <Button
          fullWidth
          className={classes.secondary}
          variant="contained"
          onClick={() =>
            history.push({
              pathname: Routes.addOccupants,
              state: {
                agreement: currentAgreement,
                update: data,
              },
            })
          }
        >
          {t("Edit")}
        </Button>
      </Box>
    </>
  );
};
export default withNamespaces("occupants")(OccupantsList);

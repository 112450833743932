import ChevronRight from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Hidden,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DialogDrawer } from "../../components";
import { ProfileSimmaer } from "../../components/simmer/profileSimmer";
import { config } from "../../config";
import { AlertContext, AuthContext, FirebaseContext } from "../../contexts";
import { GETCURRENCY } from "../../graphql/queries";
// import { LOG_OUT_TOKEN } from "../../graphql/mutations";
import { NetworkCall } from "../../networkcall";
import { BackendRoutes, Routes } from "../../router/routes";
import { AlertProps, concat_string, FontFamilySwitch, generateImageUrl, img_size, LanguageOptions, LocalStorageKeys, NetWorkCallMethods, RetainLocalStorage } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { ContactUs } from "./components/contactDrawer";
import { AgreementList } from "../../components/agreementlist";
import { Company } from "./assets/company";
import { Currency } from "./assets/currency";
import { Language } from "./assets/language";
import { App } from "./assets/app";
import { Contact } from "./assets/contact";
import { TermsPolicies } from "./assets/terms_policies";
import { TermsCondition } from "./assets/terms_condition";
import { TermOfService } from "./assets/term_of_service";
import { PrivacyPolicy } from "./assets/privacy_policy";
import { CookiePolicy } from "./assets/cookie_policy";
import { Caller } from "./assets/caller";
import { SupportIcon } from "./assets/supportIcon";
import { ChatIcon } from "./assets/chatIcon";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: theme.palette.dashboard.primary,
  },
  screenScrollEffect: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  // screen: {
  //   borderRadius: "16px",
  //   border: "1px solid #F5F7FA",
  //   background: theme.palette.background.tertiary,
  // },
  topNavBarStyle: {
    // padding: "0px 16px 0px 16px",
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  profileCardStyle: {
    padding: "24px 16px 16px 16px",
    backgroundImage: `url(${"/images/Illustration.svg"})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50%",
    background: theme.palette.background.tertiary1,
  },
  detailsCardStyle: {
    borderRadius: theme.palette.borderRadius,
    padding: "12px",
    backgroundColor: theme.palette.background.tertiary1,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "100%",
    backgroundPositionY: "100%",
  },
  bottomNavigationBarStyle: {
    borderRadius: "4px 4px 0px 0px",
    position: "fixed",
    width: "-webkit-fill-available",
  },
  drawer: {
    "& .css-9emuhu-MuiPaper-root-MuiDrawer-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  dialog: {
    "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  bottomSheetStyle: {
    background: "white",
    borderRadius: "4px 4px 0px 0px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  dialogStyle: {
    background: "white",
    borderRadius: theme.palette.borderRadius,
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  drawerHeaderTextStyle1: {
    textAlign: "center",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  drawerBodyStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerButtonStyle: {
    "&:hover": "none",
    borderRadius: theme.palette.borderRadius,
    padding: "14px",
    background: theme.palette.primary.main,
    fontSize: "0.875rem",
    fontWeight: "normal",
    color: "#FFF",
  },
  drawerButtonStyle1: {
    "&:hover": {
      background: "white",
      color: theme.palette.primary.main,
    },
    borderRadius: theme.palette.borderRadius,
    padding: "14px",
    background: "white",
    fontSize: "0.875rem",
    fontWeight: "normal",
    color: theme.palette.primary.main,
  },
  containers: {
    "&.MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    overflow: "hidden",
  },
  text: {
    fontSize: "0.875rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().bold,
    direction: "ltr",
    textAlign: "-webkit-match-parent"
  },
  role: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#6A68CC",
  },
  type: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#27AFCF",
  },
  name: {
    fontSize: "1.5rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: "#091B29",
  },
  subText: {
    fontSize: "0.875rem",
    color: "#091B29",
    fontFamily: FontFamilySwitch().bold,
    wordWrap: "break-word",
  },
  aboutpage: {
    textDecoration: "none !important"
  },
  drawerHeader: {
    position: "relative",
  },
  drawerTitle: {
    color: theme.typography.color.primary,
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().extraBold,
    marginBottom: "4px",
    textAlign: "center",
  },

  itle: {
    color: theme.typography.color.secondary,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    textAlign: "center",
    marginBottom: "12px"
  },

  drawerHeadCloseIcon: {
    position: "absolute",
    top: "4px",
    right: "6px",
    cursor: "pointer"
  },
}));

const languageOptions = [
  { value: "en", label: "English" },
  { value: "ar", label: "Arabic" },
  { value: "ta", label: "Tamil" },
];

const ProfileView = (props) => {
  const { t = () => false, i18n, handleTopBarState } = props;
  const auth = React.useContext(AuthContext)
  const language = localStorage.getItem("i18nextLng")
  const classes = useStyles({ language: auth?.auth?.auth?.language });
  const history = useHistory();
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const firebaseContext = React.useContext(FirebaseContext);
  const [data, setDate] = React.useState({
    user: {},
    role: {},
  });
  const [contactDrawer, setContactDrawer] = React.useState(false)
  const [openDrawer, setopenDrawer] = React.useState(false);
  const [currencyDrawer, setCurrencyDrawer] = React.useState(false)
  const [openAboutUs, setOpenAboutUs] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [currency, setCurrency] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const [languageModal, setLanguageModal] = React.useState({
    open: false,
    language: null,
  });
  const size = useWindowDimensions();
  const [selectedLanguage, setSelectedLanguage] = React.useState(LanguageOptions?.[0])
  const [openLanguage, setOpenLanguage] = React.useState(false)
  const [is_terms_policies_open, set_is_terms_policies_open] = React.useState(false);
  const [profile_menu_state, set_profile_menu_state] = React.useState({
    about_company: {
      icon: <Company />,
      title: t("About"),
      onClick: () => false,
    },
    set_currency: {
      icon: <Currency />,
      title: t("Set Currency"),
      onClick: () => openCurrencySwitcher(),
    },
    set_language: {
      icon: <Language />,
      title: t("Set Language"),
      onClick: () => openLanguageSwitcher(),
    },
    about_app: {
      icon: <App />,
      title: t("About App"),
      onClick: () => openLink("https://propgoto.com/about_us.html"),
    },
    contact_us: {
      icon: <Contact />,
      title: t("Contact Us"),
      onClick: () => openContactUs(),
    },
    terms_policies: {
      icon: <TermsPolicies />,
      title: t("terms & policies"),
      onClick: () => handleTermsPolicies(),
      sub_menus: {
        terms_condition: {
          icon: (<TermsCondition />),
          title: t("terms & conditions"),
          sub_title: "",
          content: "",
        },
        term_of_service: {
          icon: (<TermOfService />),
          title: t("term of service"),
          sub_title: "Property Automate",
          content: "",
        },
        privacy_policy: {
          icon: (<PrivacyPolicy />),
          title: t("privacy policy"),
          sub_title: "Property Automate",
          content: "",
        },
        cookie_policy: {
          icon: (<CookiePolicy />),
          title: t("cookie policy"),
          sub_title: "Property Automate",
          content: "",
        },
      }
    },
  });
  const [is_terms_policies_viewer_open, set_is_terms_policies_viewer_open] = React.useState(false);
  const [terms_policies_viewer_state, set_terms_policies_viewer_state] = React.useState({
    title: "",
    content: "",
  });

  const handleDrawerClose = () => {
    setCurrencyDrawer(false);
  };

  const openCurrencySwitcher = () => {
    setCurrencyDrawer(!currencyDrawer);
  };

  const handleDrawerClose1 = () => {
    setContactDrawer(false);
  };

  const openContactUs = () => {
    setContactDrawer(!contactDrawer);
  };

  const handleTermsPolicies = () => {
    set_is_terms_policies_open(!is_terms_policies_open);
  };

  // logout
  const getLogoutdeviceToken = () => {
    const params = {
      token: localStorage.getItem(LocalStorageKeys?.deviceToken),
      app_build: config.app_x_build,
    };
    NetworkCall(
      `${config.api_url}/logout`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        RetainLocalStorage();
        firebaseContext.deleteToken();
        history.push(Routes.login);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  // CURRENCY 

  const getCurrency = () => {
    const data = {
      query: GETCURRENCY,
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setCurrency(response?.data?.data?.currency_master)
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  }

  const getTermsPolicies = () => {
    NetworkCall(
      `${config.api_url}${BackendRoutes.client_get_app_configuration}`,
      NetWorkCallMethods.get,
      data, null, true, false
    ).then((response) => {
      let _ = response?.data?.data;

      const local_currency = JSON.parse(localStorage.getItem(LocalStorageKeys.currencyId)) ?? "";

      let temp_profile_menu_state = {
        ...profile_menu_state,
        about_company: {
          icon: <Company />,
          title: _?.company_name ? `${t("About")} (${_?.company_name})` : `${t("about")}`,
          onClick: () => _?.company_website ? openLink(`http://${_?.company_website}`) : false,
        },
        set_currency: {
          ...profile_menu_state?.set_currency,
          id: local_currency?.id,
          title: `${t("Set Currency")}
          ${((local_currency?.name || local_currency?.symbol) &&
              (" (" + concat_string(
                {
                  name: local_currency?.name,
                  symbol: local_currency?.symbol,
                },
                ["name", "symbol"],
                ", ", ""
              ) + ")")) ?? ""}`,
        },
        terms_policies: {
          ...profile_menu_state?.terms_policies,
          sub_menus: {
            ...profile_menu_state?.terms_policies?.sub_menus,
            terms_condition: {
              ...profile_menu_state?.terms_policies?.sub_menus?.terms_condition,
              sub_title: _?.client_name,
              content: _?.terms_conditions,
            },
            term_of_service: {
              ...profile_menu_state?.terms_policies?.sub_menus?.term_of_service,
              content: _?.term_of_service,
            },
            privacy_policy: {
              ...profile_menu_state?.terms_policies?.sub_menus?.privacy_policy,
              content: _?.privacy_policy,
            },
            cookie_policy: {
              ...profile_menu_state?.terms_policies?.sub_menus?.cookie_policy,
              content: _?.cookie_policy,
            },
          }
        },
      };

      set_profile_menu_state(temp_profile_menu_state);
    })
      .catch((error) => {
        alert.setSnack({
          ...alert, open: true, msg: t("SomethingWentwrong"),
          severity: AlertProps.severity.error,
        });
      });
  };

  const currencyDataStore = (val) => {
    set_profile_menu_state({
      ...profile_menu_state,
      set_currency: {
        ...profile_menu_state?.set_currency,
        id: val?.id,
        title: `${t("Set Currency")}
        ${((val?.name || val?.symbol) &&
            (" (" + concat_string(
              {
                name: val?.name,
                symbol: val?.symbol,
              },
              ["name", "symbol"],
              ", ", ""
            ) + ")")) ?? ""}`,
      }
    });
    localStorage.setItem(LocalStorageKeys.currencyId, JSON.stringify(val));
  }


  // logout
  // const getLogoutdeviceToken = () => {
  //   const payload = {
  //     query: LOG_OUT_TOKEN,
  //     variables: {
  //       id: localStorage.getItem(LocalStorageKeys?.userProfileID),
  //       token: localStorage.getItem(LocalStorageKeys?.deviceToken),
  //       data: {
  //         is_active: false,
  //       },
  //     },
  //   };
  //   NetworkCall(
  //     `${config.graphql_url}`,
  //     NetWorkCallMethods.post,
  //     payload,
  //     null,
  //     true,
  //     false
  //   )
  //     .then((response) => {
  //       RetainLocalStorage();
  //       firebaseContext.deleteToken();
  //       history.push(Routes.login);
  //     })
  //     .catch((error) => {
  //       alert.setSnack({
  //         ...alert,
  //         open: true,
  //         severity: AlertProps.severity.error,
  //         msg: "Some Thing Went Wrong.",
  //       });
  //     });
  // };
  const Logout = () => {
    setopenDrawer(true);
  };
  // const handleAboutUs = () => {
  //   setOpenAboutUs(true);
  // };
  React.useEffect(() => {
    handleTopBarState({
      t: t,
      note: true,
      i18n: i18n,
      new: data?.unread,
    })
    if (authToken !== null && authToken !== "") {
      NetworkCall(
        `${config.api_url}/user-info`,
        NetWorkCallMethods.get,
        null,
        null,
        true,
        false
      )
        .then((response) => {
          setDate({
            user: response?.data?.data?.user_profiles?.[0],
            role: response?.data?.data?.role?.[0],
          });
          setLoading(false);
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        });
    } else {
      RetainLocalStorage();
      history.push(Routes.login);
    }
    let getselectedlag = i18n.language;
    setLanguageModal({
      ...languageModal,
      language:
        languageOptions.filter((val) => val.value === getselectedlag)?.[0] ??
        languageOptions[0],
    });
    getCurrency();
    getTermsPolicies();
    // eslint-disable-next-line
  }, []);

  // change Language function
  // const changeLanguage = (type, val) => {
  //   if (type === "modal") {
  //     let getselectedlag = i18n.language;
  //     setLanguageModal({
  //       ...languageModal,
  //       opne: val,
  //       language:
  //         languageOptions.filter((val) => val.value === getselectedlag)?.[0] ??
  //         languageOptions[0],
  //     });
  //   } else if (type === "language") {
  //     setLanguageModal({
  //       ...languageModal,
  //       language: val,
  //     });
  //   }
  // };

  // const saveLanguage = () => {
  //   i18n.changeLanguage(languageModal?.language?.value);
  //   setLanguageModal({
  //     ...languageModal,
  //     opne: false,
  //   });
  //   auth.setAuth({
  //     ...auth,
  //     auth: {
  //       language: languageModal?.language?.value
  //     }
  //   })
  // };

  // const myBookingNavigate = () => {
  //   history.push({
  //     pathname: Routes.mybooking
  //   })
  // }


  const updateLanguage = (data) => {
    setSelectedLanguage(data)
    saveLanguage(data)
  }

  const openLanguageSwitcher = () => {
    let getselectedlag = props?.i18n.language
    setSelectedLanguage(LanguageOptions.find((val) => val.value === getselectedlag) ?? LanguageOptions[0])
    setOpenLanguage(true)
  }

  const saveLanguage = (language) => {
    props?.i18n.changeLanguage(language?.value);
    setOpenLanguage(false)
    auth.setAuth({ ...auth })
  };


  // contactUs Drawer data
  const DrawerData = (t) => {
    return [
      {
        title: t("Call & Connect"),
        icons: <Caller />,
        subTitle: t("Our representative will attend your query"),
        number: "1800 18000 18000",
        btnText: t("Call"),
        call: true,
      },
      {
        title: t("Get Support"),
        icons: <SupportIcon />,
        subTitle: t("Q&A, blog articles"),
        btnText: t("View"),
        onClick: () => history.push(Routes.getSupport)
      },
      {
        title: t("Live Chat"),
        icons: <ChatIcon />,
        subTitle: t("Get solutions instantly"),
        btnText: t("Chat"),
      },

    ]
  }

  const openLink = (url) => window.open(url, "_blank", "noreferrer");

  return (
    <Container className={classes.root} maxWidth="sm">
      {loading ? (
        <ProfileSimmaer />
      ) : (
        <>
          {/* <TopNavBars note={false} home={false} /> */}
          <Grid
            className={classes.screenScrollEffect}
            style={{
              height: size?.height - (64 + 62),
              width: "inherit",
              marginTop: "2px",
            }}
          >
            <Grid className={classes.screen} item xs={12}>
              <Grid
                container
                direction={"row"}
                alignItems={"center"}
                className={classes.profileCardStyle}
              >
                <Grid>
                  <Avatar
                    variant="circular"
                    style={{
                      height: "128px",
                      width: "128px",
                      border: "2px solid #5078E1",
                    }}
                    onClick={() => history.push(Routes.profileEdit)}
                  >
                    <Avatar
                      variant="circular"
                      style={{
                        height: "100%",
                        width: "100%",
                        border: "2px solid #ffffff",
                      }}
                    >
                      <img
                        src={
                          generateImageUrl(data?.user?.image_url, img_size.small_square) ?? "/images/profile_image.png"
                        }
                        alt="profile_image"
                        style={{
                          height: "128px",
                          width: "128px",
                        }}
                      />
                    </Avatar>
                  </Avatar>
                  <Button
                    variant="contained"
                    style={{
                      padding: "4px 7px 4px 7px",
                      marginTop: "-30px",
                      marginLeft: "42px",
                      marginRight: "40px",
                      minWidth: "35px",
                      height: "24px",
                      backgroundColor: "#5078E1",
                      border: "2px solid #F5F7FA",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "0.75rem",
                        color: "#FFFFFF",
                      }}
                    >
                      <div onClick={() => history.push(Routes.profileEdit)}>
                        {t("edit")}
                      </div>
                    </Typography>
                  </Button>
                </Grid>
                <Box width="26px" />
                <Grid>
                  <Typography className={classes.name}>
                    {data?.user?.first_name ?? ""}&nbsp;
                    {data?.user?.last_name ?? ""}
                  </Typography>
                  <Box height={"8px"} />
                  <Grid container direction={"row"}>
                    <Box
                      style={{
                        padding: "4px 8px 4px 8px",
                        border: "1px solid #27AFCF",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography className={classes.type}>{t("Resident")}</Typography>
                    </Box>
                    <Box width="8px" />
                    {data?.role?.client_role_no && (
                      <Box
                        style={{
                          padding: "4px 8px 4px 8px",
                          border: "1px solid #6A68CC",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography className={classes.role}>
                          {data?.role?.client_role_no ?? ""}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={{ padding: "16px" }}>
                <Grid container direction={"row"}>
                  <Grid
                    style={{ padding: "0px 8px 0px 0px" }}
                    xs={6}
                    container
                    direction={"column"}
                  >
                    <Grid
                      container
                      display="inline"
                      direction={"column"}
                      className={classes.detailsCardStyle}
                      style={{
                        backgroundImage: `url(${"/images/Mask_Group_39.svg"})`,
                        height: "112px",
                      }}
                    >
                      <Avatar
                        variant="circular"
                        style={{
                          height: "28px",
                          width: "28px",
                          backgroundColor: "#46B9DA",
                        }}
                      >
                        <img src="/images/icons8-phone.svg" alt="" />
                      </Avatar>
                      <Box height="12px" />
                      {data?.user?.mobile_no &&
                        <Typography className={classes.text} noWrap>
                          {data?.user?.mobile_no_country_code ?? ""}&nbsp;
                          {data?.user?.mobile_no ?? ""}
                        </Typography>
                      }
                      <Box height={"6px"} />
                      {data?.user?.alternative_mobile_no &&
                        <Typography className={classes.text} noWrap>
                          {data?.user?.alternative_mobile_no_country_code ?? ""}
                          &nbsp;
                          {data?.user?.alternative_mobile_no ?? ""}
                        </Typography>
                      }
                    </Grid>
                  </Grid>
                  <Grid
                    style={{ padding: "0px 0px 0px 8px" }}
                    xs={6}
                    container
                    direction={"column"}
                  >
                    <Grid
                      container
                      display="inline"
                      direction={"column"}
                      className={classes.detailsCardStyle}
                      style={{
                        backgroundImage: `url(${"/images/Mask_Group_37.svg"})`,
                        // height: "-webkit-fill-available",
                        height: "112px",
                      }}
                    >
                      <Avatar
                        variant="circular"
                        style={{
                          height: "28px",
                          width: "28px",
                          backgroundColor: "#6A68CC",
                        }}
                      >
                        <img src="/images/icons8-mail.svg" alt="" />
                      </Avatar>
                      <Box height="12px" />
                      <Typography className={classes.text} noWrap>
                        {data?.user?.email_id}
                      </Typography>
                      <Box height={"6px"} />
                      <Typography className={classes.text} noWrap>
                        {data?.user?.alternative_email_id ?? ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Box height={"16px"} />
                <Grid container direction={"column"}>
                  <Grid
                    container
                    display="inline"
                    direction={"column"}
                    className={classes.detailsCardStyle}
                    style={{
                      backgroundImage: `url(${"/images/Mask_Group_38.svg"})`,
                    }}
                  >
                    <Avatar
                      variant="circular"
                      style={{
                        height: "28px",
                        width: "28px",
                        backgroundColor: "#FF4B4B",
                      }}
                    >
                      <img src="/images/icons8-map-marker.svg" alt="" />
                    </Avatar>
                    <Box height="12px" />
                    <Typography className={classes.text}>
                      {data?.user?.contact?.street_1
                        ? `${data?.user?.contact?.street_1}, `
                        : ""}
                      {data?.user?.contact?.street_2
                        ? `${data?.user?.contact?.street_2}, `
                        : ""}
                      {data?.user?.contact?.street_3
                        ? `${data?.user?.contact?.street_3}, `
                        : ""}
                      {data?.user?.contact?.district
                        ? `${data?.user?.contact?.district}, `
                        : ""}
                      {data?.user?.contact?.city
                        ? `${data?.user?.contact?.city}, `
                        : ""}
                      {data?.user?.contact?.state
                        ? `${data?.user?.contact?.state}, `
                        : ""}
                      {data?.user?.contact?.country
                        ? `${data?.user?.contact?.country}, `
                        : ""}
                      {data?.user?.contact?.zipcode
                        ? `- ${data?.user?.contact?.zipcode}`
                        : ""}
                    </Typography>
                    <Box height={"12px"} />
                  </Grid>
                </Grid>


                {/* <Box height={"16px"} /> */}

                {/* Defualt Language chaange */}
                {/* <Grid
                  onClick={() => changeLanguage("modal", true)}
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                  className={classes.detailsCardStyle}
                  style={{ cursor: "pointer" }}
                >
                  <Grid xs={10} container alignItems="center" direction={"row"}>
                    <img src="/images/signout.svg" alt="signout" />
                    <Box width={"12px"} />
                    <Typography className={classes.subText}>
                      {t("defualt_language")} - ({" "}
                      {languageModal?.language?.label} )
                    </Typography>
                  </Grid>
                  <ChevronRight />
                </Grid> */}

                <Box height={"16px"} />
                {/* CURRENCY DRAWER  */}
                <DialogDrawer
                  maxWidth="sm"
                  open={currencyDrawer}
                  onClick={handleDrawerClose}
                  header={t("Select Currency")}
                  component={
                    <>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">{t("Currency")}</FormLabel>
                        {currency.map((val) => (
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={profile_menu_state?.set_currency?.id}
                            name="radio-buttons-group"
                            onChange={() => currencyDataStore(val)}
                          >
                            <FormControlLabel value={val?.id} control={<Radio onClick={handleDrawerClose} />} label={val?.name} />
                          </RadioGroup>
                        ))}
                      </FormControl>
                    </>
                  }
                  onClose={() => setCurrencyDrawer(false)}
                />

                {/* CONTACT US DRAWER  */}

                <DialogDrawer
                  maxWidth="sm"
                  open={contactDrawer}
                  isnotTitle
                  component={
                    <Box>
                      {/* Drawer Head */}
                      <Box className={classes?.drawerHeader}>
                        <Box className={classes?.drawerHeadTextBox}>
                          <Typography className={classes?.drawerTitle}>{t("We are here to help you")}</Typography>
                          <Typography className={classes?.itle}>{t("Reach us for any queries")}</Typography>
                        </Box>
                        <Box className={classes?.drawerHeadCloseIcon}>
                          <CloseIcon onClick={handleDrawerClose1} />
                        </Box>

                      </Box>
                      {/* Drawer Content */}
                      <Box>
                        {DrawerData(t)?.map((item, index) => {
                          return (
                            <ContactUs
                              icon={item?.icons}
                              title={item?.title}
                              subTitle={item?.subTitle}
                              btnText={item?.btnText}
                              onclick={item?.onclick}
                              number={item?.number}
                              call={item?.call}
                              onClick={item?.onClick}
                            />

                          )
                        })}

                      </Box>
                    </Box>
                  }
                  onClose={() => setContactDrawer(!contactDrawer)}
                />

                <Box height={"16px"} />
                  {Object.keys(profile_menu_state)?.map((key) => {
                    return <Grid
                      onClick={profile_menu_state?.[key]?.onClick}
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                      className={classes.detailsCardStyle}
                      style={{ cursor: "pointer" }}>
                      <Grid xs={10} container alignItems="center" direction={"row"}>
                        {profile_menu_state?.[key]?.icon}
                        <Box width={"12px"} />
                        <Typography className={classes.subText} textTransform={"capitalize"}>
                        {profile_menu_state?.[key]?.title}
                        </Typography>
                      </Grid>
                      {language === "ar" ?
                        <ChevronLeftIcon sx={{ color: "#091B29" }} /> :
                        <ChevronRight sx={{ color: "#091B29" }} />
                      }
                    </Grid>
                  })}
                <Box height={"16px"} />
                <Grid
                  onClick={Logout}
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                  className={classes.detailsCardStyle}
                  style={{ cursor: "pointer" }}
                >
                  <Grid xs={10} container alignItems="center" direction={"row"}>
                    <img src="/images/signout.svg" alt="signout" />
                    <Box width={"12px"} />
                    <Typography className={classes.subText}>
                      {t("log_out")}
                    </Typography>
                  </Grid>
                  {language === "ar" ?
                  <ChevronLeftIcon sx={{ color: "#091B29" }} /> :
                  <ChevronRight sx={{ color: "#091B29" }} />
                  }
                </Grid>

                <Box height={"16px"} />
                {localStorage.getItem(LocalStorageKeys.version) &&
                  <Typography
                    style={{
                      textAlign: "center",
                      fontSize: "0.75rem",
                      color: "#091B29",
                    }}
                  >
                    {"V" + localStorage.getItem(LocalStorageKeys.version)}
                  </Typography>}
              </Grid>
            </Grid>
            <Hidden smDown>
              <Dialog
                className={classes.dialog}
                fullWidth={true}
                maxWidth="sm"
                open={openDrawer}
              >
                <Grid
                  container
                  direction="column"
                  className={classes.dialogStyle}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}
                  >
                    <Grid>
                      <Typography className={classes.drawerHeaderTextStyle}>
                        {t("confirmation")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton
                        style={{
                          padding: "0px",
                        }}
                        onClick={() => setopenDrawer(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid className={classes.drawerBodyStyle}>
                    <Typography className={classes.drawerHeaderTextStyle1}>
                      {t("are_you")}
                    </Typography>
                    <Box height={"32px"} />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => setopenDrawer(false)}
                          fullWidth
                          className={classes.drawerButtonStyle1}
                          variant="contained"
                        >
                          {t("cancel")}
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => {
                            getLogoutdeviceToken();
                          }}
                          fullWidth
                          className={classes.drawerButtonStyle}
                          variant="contained"
                        >
                          {t("log_out")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Dialog>
            </Hidden>
            <Hidden smUp>
              <Drawer
                className={classes.drawer}
                anchor="bottom"
                open={openDrawer}
              >
                <Grid
                  container
                  direction="column"
                  className={classes.bottomSheetStyle}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}
                  >
                    <Grid>
                      <Typography className={classes.drawerHeaderTextStyle}>
                        {t("confirmation")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton
                        style={{
                          padding: "0px",
                        }}
                        onClick={() => setopenDrawer(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid className={classes.drawerBodyStyle}>
                    <Typography className={classes.drawerHeaderTextStyle1}>
                      {t("are_you")}
                    </Typography>
                    <Box height={"32px"} />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => setopenDrawer(false)}
                          fullWidth
                          className={classes.drawerButtonStyle1}
                          variant="contained"
                        >
                          {t("cancel")}
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => {
                            getLogoutdeviceToken();
                          }}
                          fullWidth
                          className={classes.drawerButtonStyle}
                          variant="contained"
                        >
                          {t("log_out")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Drawer>
            </Hidden>
            <Hidden smDown>
              <Dialog
                className={classes.dialog}
                fullWidth={true}
                maxWidth="sm"
                open={openAboutUs}
              >
                <Grid
                  container
                  direction="column"
                  className={classes.dialogStyle}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}
                  >
                    <Grid>
                      <Typography className={classes.drawerHeaderTextStyle}>
                        {t("about")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton
                        style={{
                          padding: "0px",
                        }}
                        onClick={() => setOpenAboutUs(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid className={classes.drawerBodyStyle}>
                    <Grid
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                      style={{ height: "100px" }}
                    >
                      <img
                        src="/images/Propgoto logo-01.svg"
                        alt=""
                        style={{
                          height: "inherit",
                          width: "50%",
                          objectFit: "contain",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Dialog>
            </Hidden>
            <Hidden smUp>
              <Drawer
                className={classes.drawer}
                anchor="bottom"
                open={openAboutUs}
              >
                <Grid
                  container
                  direction="column"
                  className={classes.bottomSheetStyle}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}
                  >
                    <Grid>
                      <Typography className={classes.drawerHeaderTextStyle}>
                        {t("about")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton
                        style={{
                          padding: "0px",
                        }}
                        onClick={() => setOpenAboutUs(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid className={classes.drawerBodyStyle}>
                    <Grid
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                      style={{ height: "100px" }}
                    >
                      <img
                        src="/images/Propgoto logo-01.svg"
                        alt=""
                        style={{
                          height: "inherit",
                          width: "50%",
                          objectFit: "contain",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Drawer>
            </Hidden>

            {/* Modal Language chaange */}
            {/* <Hidden smDown>
              <Dialog
                onClose={() => changeLanguage("modal", false)}
                className={classes.dialog}
                fullWidth={true}
                maxWidth="sm"
                open={languageModal?.opne}
              >
                <Grid
                  container
                  direction="column"
                  className={classes.dialogStyle}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}
                  >
                    <Grid>
                      <Typography className={classes.drawerHeaderTextStyle}>
                        {t("list.language")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton
                        style={{
                          padding: "0px",
                        }}
                        onClick={() => changeLanguage("modal", false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    className={classes.drawerBodyStyle}
                    style={{ height: "151px" }}
                  >
                    <SelectBox
                      label={`${t("select")} ${t("list.language")}`}
                      isRequired={false}
                      isPaginate={false}
                      createSelect={false}
                      value={languageModal?.language}
                      placeholder={""}
                      options={languageOptions}
                      onChange={(e) => changeLanguage("language", e)}
                    />

                    <Box height={"16px"} />
                    <Grid
                      justifyContent={"flex-end"}
                      alignItems={"flex-end"}
                      container
                    >
                      <Button
                        onClick={() => saveLanguage()}
                        variant="contained"
                      >
                        {t("save")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Dialog>
            </Hidden>
            <Hidden smUp>
              <Drawer
                anchor="bottom"
                className={classes.drawer}
                open={languageModal?.opne}
                onClose={() => changeLanguage("modal", false)}
              >
                <Grid
                  container
                  direction="column"
                  className={classes.bottomSheetStyle}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}
                  >
                    <Grid>
                      <Typography className={classes.drawerHeaderTextStyle}>
                        {t("list.language")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton
                        style={{
                          padding: "0px",
                        }}
                        onClick={() => changeLanguage("modal", false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    className={classes.drawerBodyStyle}
                    style={{ height: "151px" }}
                  >
                    <SelectBox
                      label={`${t("select")} ${t("list.language")}`}
                      isRequired={false}
                      isPaginate={false}
                      createSelect={false}
                      value={languageModal?.language}
                      placeholder={""}
                      options={languageOptions}
                      onChange={(e) => changeLanguage("language", e)}
                    />

                    <Box height={"16px"} />
                    <Grid
                      justifyContent={"flex-end"}
                      alignItems={"flex-end"}
                      container
                    >
                      <Button
                        onClick={() => saveLanguage()}
                        variant="contained"
                      >
                        {t("save")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Drawer>
            </Hidden> */}
            <DialogDrawer
              height={"400px"}
              open={openLanguage}
              header={t("Choose Language")}
              padding={"0px 16px 16px 16px"}
              component={
                <AgreementList
                  languageOptions={LanguageOptions}
                  selectedLanguage={selectedLanguage}
                  updateLanguage={updateLanguage}
                />
              }
              onClose={() => setOpenLanguage(false)} />

              <DialogDrawer
                height={"400px"}
                open={is_terms_policies_open}
                onClose={() => set_is_terms_policies_open(false)}
                header={t("terms & policies")}
                padding={"16px"}
                header_text_transform={"capitalize"}
                component={
                  <Stack direction={"column"} spacing={"12px"}>
                    {Object.keys(profile_menu_state?.terms_policies?.sub_menus)?.map((key) => {
                      let menu = profile_menu_state?.terms_policies?.sub_menus;
                      return (
                        menu?.[key]?.content &&
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}
                          sx={{
                            cursor: "pointer",
                            border: "1px solid #E4E8EE",
                            borderRadius: "8px",
                            padding: "16px",
                          }}
                          onClick={()=>{
                            set_terms_policies_viewer_state({
                              title: menu?.[key]?.title,
                              content: menu?.[key]?.content,
                            });
                            set_is_terms_policies_viewer_open(true);
                            set_is_terms_policies_open(false);
                          }}>
                          <Stack spacing={"12px"} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            {menu?.[key]?.icon}
                            <Stack direction={"column"} spacing={"5px"}>
                              <Typography
                                sx={{
                                  fontFamily: FontFamilySwitch().bold,
                                  fontSize: "0.875rem",
                                  color: "#091B29",
                                  textTransform: "capitalize",
                                }}>
                                {menu?.[key]?.title}
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: FontFamilySwitch().semiBold,
                                  fontSize: "0.75rem",
                                  color: "#4E5A6B",
                                  textTransform: "capitalize",
                                }}>
                                {menu?.[key]?.sub_title}
                              </Typography>
                            </Stack>
                          </Stack>
                          <ChevronRight htmlColor="#98A0AC" />
                        </Stack>
                      )
                    })}
                  </Stack>
                } />

              <DialogDrawer
                height={"100%"}
                open={is_terms_policies_viewer_open}
                onClose={() => {
                  set_is_terms_policies_open(true);
                  set_is_terms_policies_viewer_open(false);
                }}
                header={t(terms_policies_viewer_state?.title)}
                padding={"16px"}
                header_text_transform={"capitalize"}
                maxWidth={"sm"}
                component={
                  <div dangerouslySetInnerHTML={{ __html: terms_policies_viewer_state?.content }} />
                } />
          </Grid>
        </>
      )}
    </Container>
  );
};
export default withNamespaces("profile")(ProfileView);

/* eslint-disable react/jsx-no-target-blank */
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { generateImageUrl } from "../../utils";
import { convertUrlToFileObject, findImageSize, image_size, img_size } from "../../utils/constants";


const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    objectFit: "contain",
    height: "100%",
    borderRadius: theme.palette.borderRadius2
  },
}));

export const CarouselSliders = ({ data = [], autoScroll = false, onClick = false, borderRadius = "" }) => {
  var settings = {
    dots: true,
    infinite: true,
    // slidesToShow: 3,
    // slidesToScroll: 1,
    autoplay: autoScroll,
    speed: 1000,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false
  };


  const [ads,setAds]=React.useState([])

  const extractFile=async(url)=>{

    const result = [];
    
    for (const item of data) {
      result.push( {
        size: await findImageSize(await convertUrlToFileObject(item?.url)),
        url:item?.url,
        action_url:item?.action_url
      })
    }
    const size_restriction_images=result.filter(i=>i?.size?.height === image_size?.height && i?.size?.width === image_size?.width )
    setAds(size_restriction_images)

  }

  React.useEffect(() => {

    extractFile()
    // eslint-disable-next-line
  }, [data])

  const classes = useStyles()

  return (
    <div style={{ cursor: "pointer" }} >
      <Grid container justifyContent="center">
        <Grid item xs={12} alignItems={"center"}>
          <Slider {...settings}>
            {ads.map((val, index) => {
              return (
                <div
                key={index}
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={onClick ? onClick : null}
                >
                  <a href={val?.action_url} target="_blank" >
                    <img
                      src={generateImageUrl(val.url, img_size.small_square)} // --- pending
                      alt="img"
                      className={classes.image}
                    />
                  </a>
                </div>
              );
            })}
          </Slider>
        </Grid>
      </Grid>
    </div>
  );
};

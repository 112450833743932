import React from "react";
import  CommunityDiscussion  from "./communityDiscussion";
import { withBottombar } from "../../HOCs";

class CommunityDiscussionParent extends React.Component {
  render() {
    return <CommunityDiscussion {...this?.props} />;
  }
}

const props = {
  top_bar: "title_bar",
}

export default withBottombar(CommunityDiscussionParent, props);

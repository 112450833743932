import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FontFamilySwitch } from '../../utils';

const useStyles = makeStyles((theme) => ({
    message: {
        alignItems: "center",
        fontSize: "1.125rem",
        fontFamily: FontFamilySwitch().semiBold
    }
}))


export const LoadingSection = (props) => {

    const classes = useStyles();


    const {
        message,
        top
    } = props;

    return <div>
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ marginTop: top }}
        >
            <Grid item>
                <CircularProgress />
            </Grid>
            <Grid item>
                <Typography className={classes.message}>
                    {message}
                </Typography>
            </Grid>
        </Grid>
    </div>
}

LoadingSection.propTypes = {
    message: PropTypes.string,
    top: PropTypes.string
}

LoadingSection.defaultProps = {
    message: 'Loading...',
    top: '30vh'
}


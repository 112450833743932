import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
// import styled from '@mui/material/styles/styled';
// import useTheme from '@mui/material/styles/useTheme';
import useTheme from "@mui/styles/useTheme";
import React from "react";
import { CustomTypography } from ".";
import { FontFamilySwitch } from "../../../../utils";

// const CustomImage = styled('img')(({ theme }) => ({
//     width: 73,
//     height: 73,
//     borderRadius: 12
// }))

export const AnnouncementCard = (props) => {
  const {
    logo = "",
    category = "",
    title = "",
    postedOn = "",
    onClickSlider = null,
  } = props;

  const theme = useTheme();

  return (
    <Box
      sx={[
        {
          // height: "115px",
          "&:focus-visible": {
            outline: "none",
          },
        },
      ]}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        sx={(theme) => ({
          // backgroundColor: "#FFFFFF",
          boxShadow: "0px 3px 10px #5C86CB2E",
          cursor: "pointer",
          height: "275px",
          minWidth:"250px",
          backgroundImage: `url(${logo})`,
          width:"100%",
          backgroundPosition: "center",
          backgroundSize: "contain",
          borderRadius: "12px",
          backgroundRepeat:"no-repeat"
        })}
        flexWrap={"wrap"}
        onClick={onClickSlider}
      >
        {/* <CustomImage
                    src={logo}
                    alt={"annoucemnet_img"}
                /> */}
        <div
          style={{
            height: "100%",
            width: "100%",
            // margin: "auto",
            background: "linear-gradient(#091B2900, #000000C4)",
            borderRadius: theme.palette.borderRadius2,
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              bottom: "16px",
              left: "16px",
              width: "90%",
            }}
          >
            {category && (
              <CustomTypography
                fontSize={10}
                color={theme.palette.background.tertiary1}
                fontFamily={FontFamilySwitch().bold}
                sx={{
                  padding: "2px 4px",
                  backgroundColor: "#5078E1",
                  borderRadius: "4px",
                  width: "fit-content",
                }}
              >
                {category}
              </CustomTypography>
            )}
            {title && (
              <CustomTypography
                color={theme.palette.background.tertiary1}
                fontFamily={FontFamilySwitch().bold}
                sx={{
                  marginBlock: 1 / 2,
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {title}
              </CustomTypography>
            )}
            {postedOn && (
              <CustomTypography
                fontSize={10}
                color={theme.palette.border.primary}
              >
                {postedOn}
              </CustomTypography>
            )}
          </Box>
        </div>
      </Stack>
    </Box>
  );
};

import { Avatar, Box, Grid, Switch, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, FontFamilySwitch, NetWorkCallMethods } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  roots: {
    padding: "8px",
    margin: "0px 8px",
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  name: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    display: "flex",
    alignItems: "center",
  },
  call: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    direction: "ltr"
  },
  mail: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
  },
  isShare: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    marginBottom: "4px"
  },
  img: {
    // objectFit: "cover",
    // height: "100%",
  },
  tagStyle: {
    backgroundColor: "#F1E6FE",
    borderRadius: "4px",
    padding: "2px 1px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#5D427F",
    textTransform: "uppercase",
    display: "inline",
  },
  avatar: {
    backgroundColor: "#F0F4FF",
    width: "48px",
    height: "48px",
    marginInlineEnd: "8px"
  },
  details: {
    flexFlow: 'wrap !important'
  }
}));
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: '4px',
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
export const ContactList = (props) => {
  const { t=()=>false } = props
  const classes = useStyles();
  const alert = React.useContext(AlertContext);
  const statusApi = (contactId, isShare) => {
    const data = {
      tenantId: `${config.tenantid}`,
      contact_id: contactId,
      is_share: isShare,
    };
    NetworkCall(
      `${config.api_url}/community/contact/status`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        props?.MyContacts(props?.unitID);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing went wrong"),
        });
      });
  };

  return (
    <div className={classes.root}>
      {props?.call ? (
        <a
          href={`tel:${props?.data?.country_code ?? ""}${props?.data?.contact_number ?? ""}`}

          style={{ textDecoration: "none", color: "#071741" }}
        >
          <Grid container>
            <Grid item xs={12} className={classes.roots}>
              <Grid container>
                <Grid item xs={11}>
                  <Box display="flex" alignItems={"center"}>
                    <Box>
                      <Avatar className={classes.avatar}>
                        <img
                          src={
                            props?.neighbours
                              ? "/images/neigboursperson.svg"
                              : "/images/phone2.svg"
                          }
                          alt={"phone"}
                          className={classes.img}
                        />
                      </Avatar>
                    </Box>
                    <Box flexGrow={1} marginTop="6px" marginLeft="8px">
                      <Box
                        display="flex"
                        alignItems="center"
                        marginBottom="2px"
                      >
                        <Typography className={classes.name}>
                          {props?.data?.name} &nbsp;{" "}
                          <Typography className={classes.tagStyle}>
                            &nbsp;&nbsp;{props?.data?.profession} &nbsp;&nbsp;
                          </Typography>
                        </Typography>
                      </Box>

                      {(props?.data?.property_name ||
                        props?.data?.unit_name) && (
                          <Box display="flex" className={classes.details}>
                            <Box>
                              <Typography className={classes.call}>
                                {props?.data?.property_name}&nbsp;
                              </Typography>
                            </Box>

                            {props?.data?.unit_name && (
                              <>
                                <Box className={classes.periodStyle} />
                                <Box>
                                  <Typography className={classes.mail}>
                                    {props?.data?.unit_name}
                                  </Typography>
                                </Box>
                              </>
                            )}
                          </Box>
                        )}

                      <Box display="flex" className={classes.details}>
                        <Box>
                          <Typography className={classes.call}>
                            {props?.data?.country_code}&nbsp;
                            {props?.data?.contact_number}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                {props?.switch && (
                  <Grid item xs={1}>
                    <Typography className={classes.mail}>
                      {props?.data?.is_share ? t("Public") : t("Private")}
                    </Typography>
                    <AntSwitch
                      checked={props?.data?.is_share}
                      onChange={(e) =>
                        statusApi(props?.data?.id, e.target.checked)
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            {/* <Grid item xs={12}>
              <Divider variant="middle" sx={{ backgroundColor: "#CED3DD" }} />
            </Grid> */}
          </Grid>
        </a>
      ) : (
        <Grid container>
          <Grid item xs={12} className={classes.roots}>
            <Grid container alignItems={"center"}>
              <Grid
                item
                xs={11}
                onClick={() =>
                  props?.switch ? "" : props?.onClick(props?.data)
                }
              >
                <Box display="flex" alignItems={"center"}>
                  <Box>
                    {props?.switch ? (
                      <a
                        href={`tel:${props?.data?.country_code}+${props?.data?.contact_number}`}
                        style={{ textDecoration: "none", color: "#071741" }}
                      >
                        <Avatar className={classes.avatar}>
                          <img
                            src={
                              props?.neighbours
                                ? "/images/neigboursperson.svg"
                                : "/images/phone2.svg"
                            }
                            alt={"phone"}
                            className={classes.img}
                          />
                        </Avatar>
                      </a>
                    ) : (
                      <Avatar className={classes.avatar}>
                        <img
                          src={
                            props?.neighbours
                              ? "/images/neigboursperson.svg"
                              : "/images/phone2.svg"
                          }
                          alt={"phone"}
                          className={classes.img}
                        />
                      </Avatar>
                    )}
                  </Box>
                  <Box
                    flexGrow={1}
                    marginTop="6px"
                    marginLeft="8px"
                    onClick={() =>
                      props?.switch ? props?.onClick(props?.data) : ""
                    }
                  >
                    <Box display="flex" alignItems="center" marginBottom="2px">
                      <Typography className={classes.name}>
                        {props?.data?.name} &nbsp;{" "}
                        <span className={classes.tagStyle}>
                          {" "}
                          &nbsp;&nbsp;&nbsp;{props?.data?.profession}{" "}
                          &nbsp;&nbsp;&nbsp;
                        </span>
                      </Typography>
                    </Box>

                    <Box className={classes.details} display="flex">
                      <Box>
                        <Typography className={classes.call}>
                          {props?.data?.property_name}&nbsp;
                        </Typography>
                      </Box>
                      {props?.data?.unit_name && (
                        <>
                          <Box className={classes.periodStyle} />
                          <Box>
                            <Typography className={classes.mail}>
                              {props?.data?.unit_name}
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                    <Box className={classes.details} display="flex">
                      <Box>
                        <Typography className={classes.call}>
                          {props?.data?.country_code}&nbsp;
                          {props?.data?.contact_number}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              {props?.switch && (
                <Grid item xs={1}>
                  <Typography className={classes.isShare}>
                  {props?.data?.is_share ? t("Public") : t("Private")}
                  </Typography>
                  <AntSwitch
                    checked={props?.data?.is_share}
                    onChange={(e) =>
                      statusApi(props?.data?.id, e.target.checked)
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* <Grid item xs={12}>
            <Divider variant="middle" sx={{ backgroundColor: "#CED3DD" }} />
          </Grid> */}
        </Grid>
      )}
    </div>
  );
};

import { Avatar, Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import { ammenitiesBookingStyles } from "./styles";
import { NetWorkCallMethods, generateImageUrl, img_size, stringAvatar } from "../../utils";
import { Routes } from "../../router/routes";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NoAmenitySvg } from "../../assets/noAmenity";
import AmenitiesReplace from "../../assets/amenitiesReplace";





const AmenitiesDetailsOne = (props) => {
    const { t = () => false, handleTopBarState } = props
    const classes = ammenitiesBookingStyles();
    const size = useWindowDimensions();
    const state = useLocation().state;
    const history = useHistory();
    // const selectedTab = state?.tab;
    // const [value, setValue] = React.useState(selectedTab ?? "1");
    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };
    const [selectedCategory, setSelectedCategory] = React.useState(state?.data?.amenities_categories?.[0]?.amenity_category_id);
    const [pastBooing, setPastBooking] = React.useState([])
    // past booking

    const getPastBooking = (date) => {

        let payload = {
            property_id: state?.selectedProperty?.property_id
        }

        NetworkCall(
            `${config.api_url}/amenities_booking_v2/last_five_booking_amenities`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setPastBooking(res?.data?.data)
            })
            .catch((error) => {
                // alert.setSnack({
                //     ...alert,
                //     open: true,
                //     severity: AlertProps.severity.error,
                //     msg: t("No slots available for Booking"),
                // });
            });
    };


    React.useEffect(() => {
        handleTopBarState({
            text: t("Amenity Booking")
        })
        getPastBooking()
        // eslint-disable-next-line
    }, []);

    const property_images = [
        {
            action_url: "#",
            url: state?.data?.prop_assets[0]?.property_logo ?? ""
        }
    ]

    const selectCard = (cardData) => {
        history.push({
            pathname: Routes.amenitiesBookingsDetails,
            state: {
                data: cardData,
                selectedProperty: state?.selectedProperty,
                property_images: property_images,
                type: "direct",
                amenity_data: [{
                    symbol: cardData?.symbol,
                    rate: cardData?.amount,
                    category: cardData?.amenity_category,
                    amenities_name: cardData?.amenities_name,
                    period: cardData?.period,
                    facility_id: cardData?.facility_id,
                    assets: cardData?.amenity_assets,
                    participants_count: cardData?.participants_count
                }],
                unitData: state?.unitData,
                selectedDate: new Date()
            },
        });
    }

    const handleClickScroll = (sectionId) => {
        setSelectedCategory(sectionId)
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    return (
        <Container
            className={classes.root}
            maxWidth="sm"
        // style={{ height: size?.height - (48 + 55), overflow: "scroll" }}
        >
            {state?.data?.amenities_categories?.length > 0 ?
                <Grid Container>
                    {/* tabs switch */}
                    <Grid item xs={12} className={classes.tabroot} p={1}>
                        <ScrollContainer className="scroll-container">
                            {/* <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="none"
                            textColor="secondary"
                            className={classes.tabroot}
                            variant="scrollable"
                            visibleScrollbar={false}
                            scrollButtons={false}
                        >
                            
                        </Tabs> */}
                            <Stack direction={"row"} spacing={1}>
                                {state?.data?.amenities_categories?.map((_, index) => {
                                    return (
                                        // <Button
                                        //     onClick={() => handleClickScroll(_?.amenity_category_id)}
                                        //     sx={{whiteSpace:"nowrap"}}
                                        //     className={_?.amenity_category_id === selectedCategory ? classes.selectedButton : classes.unselectedButton}
                                        // >
                                        //     {_?.amenity_category}
                                        // </Button>

                                        <Typography onClick={() => handleClickScroll(_?.amenity_category_id)}
                                            sx={{ whiteSpace: "nowrap", padding: "8px 12px" }}
                                            className={_?.amenity_category_id === selectedCategory ? classes.selectedButton : classes.unselectedButton}>
                                            {_?.amenity_category ?? "-"}
                                        </Typography>
                                    )
                                })}
                            </Stack>
                        </ScrollContainer>
                    </Grid>
                    {/* {value === "1" && */}
                    <div style={{ height: size?.height - (48 + 80), overflow: "scroll" }}>
                        <Grid className={classes.tabSwitchRoot}>
                            {/* past Bookings */}
                            {pastBooing?.length > 0 && <Box className={classes.pastBookings}>
                                <Typography className={classes.labels}>{t("Recently Booked")}</Typography>
                                <ScrollContainer>
                                    <Stack className={classes.pastAmmenityStack} spacing={2} direction="row">
                                        {pastBooing?.map((data) => {
                                            return (
                                                <Stack onClick={() => selectCard(data)}>
                                                    <Avatar className={classes.pastAvat} src={generateImageUrl(data?.amenity_assets?.[0], img_size.small_square)} {...stringAvatar(data?.description ?? "")} />
                                                    <Typography className={classes.pastTitle}>{data?.description ?? "-"}</Typography>

                                                </Stack>
                                            )
                                        })}
                                    </Stack>
                                </ScrollContainer>
                            </Box>}
                            {/* amenity cards */}
                            <Stack container spacing={2} pt={0.5}>
                                {state?.data?.amenities_categories?.map((amenity, index) => {
                                    return (
                                        <>
                                            {/* <Grid item xs={12} id={amenity?.amenity_category_id}> */}
                                            <Typography id={amenity?.amenity_category_id} className={classes.labels1}>{amenity?.amenity_category}</Typography>
                                            {/* </Grid> */}
                                            <Grid container rowGap={"12px"} columnGap={1}>
                                                {amenity?.amenitiesItems?.map((data, index) => {
                                                    return (
                                                        <>
                                                            {/* <Grid item lg={4} md={4} sm={4} xs={6} > */}

                                                            {/* <Stack className={classes.amenityCard} onClick={() => selectCard(data)}>
                                                                <Stack className={classes.amenityStack} >
                                                                    <Avatar className={classes.amenityStackAvatar} src={generateImageUrl(data?.facility_assets?.length > 0 ? data?.facility_assets?.[0]?.url : "/images/amenities_replace.svg", img_size.small_square)} />
                                                                </Stack>
                                                                <Stack className={classes.textStack} direction="row" justifyContent="space-between" alignItems={"center"} >
                                                                    {data?.description ? cardText(data?.description) : "-"}


                                                                    <ArrowForwardRoundedIcon className={classes.arrow} />
                                                                </Stack>
                                                            </Stack> */}
                                                            {/* </Grid> */}

                                                            <Grid item md={12} sm={12} xs={12} lg={12}>
                                                                <Stack direction={"row"} justifyContent={"space-between"}
                                                                    className={classes.amenityCard} alignItems={"center"}
                                                                    onClick={() => selectCard(data)}>
                                                                    <Stack spacing={1}>
                                                                        <Stack>
                                                                            <Typography className={classes.categoryText}>{data?.amenity_category}</Typography>
                                                                            <Typography className={classes.amenityNameText} noWrap>{data?.description}</Typography>
                                                                        </Stack>
                                                                        <Typography className={classes.availableText}>{data?.free_slots ?? 0} {t("slot available")}</Typography>
                                                                    </Stack>
                                                                    {
                                                                        data?.facility_assets?.length > 0 ?
                                                                            <Avatar className={classes.amenityStackAvatar} src={generateImageUrl(data?.facility_assets?.[0]?.url, img_size.small_square)} />
                                                                            : <AmenitiesReplace />
                                                                    }
                                                                </Stack>
                                                            </Grid>
                                                        </>

                                                    )

                                                })}
                                            </Grid>
                                        </>

                                    )
                                })
                                }
                            </Stack>
                        </Grid>
                    </div>
                    {/* } */}
                </Grid>
                :
                <Box className={classes.noAmenitySvg}>
                    <NoAmenitySvg />
                </Box>
            }
        </Container >
    );
};
// As per CR commented

// const cardText = (description) => {
//     const classes = ammenitiesBookingStyles();
//     let splited = (description).split(" ")

//     let text1 = (splited?.[0]?.length + splited?.[1]?.length) <= 8 ? `${splited?.[0]} ${splited?.[1]}` : splited?.[0]
//     let text2 = (splited?.[0]?.length + splited?.[1]?.length) <= 8 ? splited?.[2]?.length > 0 ? splited?.[2] : "" : splited?.[1]?.length > 5 ? `${splited?.[1]}...` : splited?.[1]
//     return (
//         <Stack sx={{ width: 100 }}>
//             {text1?.length > 0 && <Typography className={classes.stackTitle}>{text1}</Typography>}
//             {text2?.length > 0 && <Typography className={classes.stackTitle}>{text2}</Typography>}
//         </Stack>
//     )
// }

export default withNamespaces("amenitiesDetailsOne")(AmenitiesDetailsOne)

    // < Typography className = { classes.stackTitle } noWrap width = { "80%"} >
    //     {/* {data?.description} */ }
    //                                                                 Swimming Pool
    //                                                             </Typography >
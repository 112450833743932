import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    imgbody: {
        borderRadius: "4px",
        cursor: "pointer",
        backgroundColor: "#E4EFFF",
        padding: "40px",
        height: "150px",
        marginRight: "10px",
        marginLeft: "10px"
    },
    icon: {
        display: "flex",
        justifyContent: "center",
        color: "#5078E1"
    },
    uploadedImg: {
        width: "100%",
        height: "250px",
        cursor: "pointer"
    },
    imgblock: {
        margin: "0px 30px",
        position: "relative"
    },
    selectedImg: {
        height: "64px",
        borderRadius: "4px",
        width: "100%",
        objectFit:"cover"

    },

    text: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#5078E1"

    },
    selectedimgbody: {

        display: "flex",
        '&:hover': {
            scale: 2
        }
    },
    imgtext: {
        position: "absolute",
        bottom: 10,
        color: "white",
        top: "50%",
        marginLeft: "6px",
    },
    deleteicon: {
        display: "flex",
        position: "absolute",
        bottom: 0,
        top: "-8px",
        color: "#FF4B4B",
        right: "-7px",
        cursor:"pointer",
        height:"24px",
        backgroundColor:"#fff",
        borderRadius:"50%"
    },
    dot: {
        width: "4px",
        height: "4px",
        backgroundColor: "white",
        borderRadius: "50%",
        marginTop: "5px",
        marginLeft: "4px",
        marginRight: "4px",
    },
    imgName: {
        fontFamily: FontFamilySwitch().semiBold,
        color: "#FFFFFF",
        fontSize: "0.75rem",
    },
    textName: {
        fontFamily: FontFamilySwitch().regular,
        color: "#FFFFFF",
        fontSize: "0.75rem",
    },
    hoverCard: {
        opacity: "12",
        backgroundColor: '#0000009c',
        position: 'absolute',
        width: "100%",
        height: "150px",
        borderRadius: '4px',
        top: 1,
        bottom: 0,
        right: 0,
        left: 0,
    },
    delete: {
        position: "absolute",
        top: "-4px",
        right: "-9px"
    },
    hoverIcon:{
        cursor:'pointer'
    }

}))
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { generateImageUrl, img_size } from "../../utils";
import { useStyles } from "./styles";
import { UnitCard } from "./unitCard";

export const UnitAndRequestCard = (props) => {
  const { t=()=>false } = props
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <div>
      {/* Units data */}
      {props?.data &&
        (props?.type === "unit" ? (
          <Box
            className={classes.unitRoot}
            onClick={() => {
              props?.type === "unit"
                ? props?.onClick(props?.data)
                : history.push(
                  Routes.requestDetails +
                  "?requestId=" +
                  props?.data?.id +
                  "&requestNo=" +
                  props?.data?.id +
                  "&requestType=" +
                  props?.data?.request_type +
                  "&status=" +
                  props?.data?.request_status
                );
            }}
          >
            <Box className={classes.cardDetails}>
              <Stack direction={"row"}>
                <Box position={"relative"}>
                  {props.type === "unit" ? (
                    <>
                      <img
                        src={
                          props?.data?.logo?.length > 0
                            ? generateImageUrl(props?.data?.logo, img_size.small_square)
                            : "/images/unitplaceholder.svg"
                        }
                        className={classes.cardImage}
                        alt="altimage"
                      />
                    </>
                  ) : (
                    <Box className={classes.relative}>
                      <img
                        src={
                          props?.data?.image_url?.length > 0
                            ? props?.data?.image_url?.substring(
                              props?.data?.image_url?.lastIndexOf(".") + 1
                            ) !== "mp4"
                              ? props?.data?.image_url
                              : "/images/unitplaceholder.svg"
                            : "/images/unitplaceholder.svg"
                        }
                        className={classes.cardImage}
                        alt="altimage"
                      />
                    </Box>
                  )}
                  <Box className={classes.typeCard}>
                      <Typography className={classes.typeName} noWrap>
                        {props?.data?.unit_category_name}
                      </Typography>
                    </Box>
                </Box>

                {/* <Box display={"uflex"} > */}
                <Box sx={{ flexGrow: 1 }}>
                  <Stack direction={"column"} spacing={"6px"}>
                    <div>
                      <Typography className={classes.title} noWrap>
                        {props?.type === "unit"
                          ? props?.data?.unit_name
                          : props?.data?.request_type}
                        &nbsp;
                        {props?.data?.unit_category && (
                          <span className={classes.categoryTitle}>
                            {props?.data?.unit_category}
                          </span>
                        )}
                        {props?.type === "request" && (
                          <Typography className={classes.bottomText}>
                            {props?.data?.request_no?.slice(0, 13)}
                          </Typography>
                        )}
                      </Typography>
                    </div>
                    <div>
                      {/* <Stack direction={"row"} justifyContent="space-between"> */}
                      <Typography className={classes.subTitle} noWrap>
                        {props?.data?.property_name}
                      </Typography>
                      {/* <Box className={classes.rightIcon}>
                      <ArrowRight />
                    </Box> */}
                      {/* </Stack> */}

                    </div>
                    <Box sx={{ maxWidth: "170px" }}>
                      <Stack direction={"row"} divider={<Divider sx={{ marginInline: "8px" }} orientation="vertical" flexItem />} justifyContent="flex-start">
                        <Stack direction={"row"}>
                          <Box
                            className={classes.iconText}
                            justifyContent={"center"}
                            alignItems={"center"}
                            textAlign="left"
                          >
                            <img src="/images/bedIcon.svg" alt="bed" />
                            {/* <Hidden smDown> */}
                            <Typography className={classes.iconName1} noWrap>
                              {props?.data?.total_bed_rooms
                                ? props?.data?.total_bed_rooms + t("Beds")
                                : "0" + t("Beds")}
                            </Typography>
                            {/* </Hidden> */}
                            {/* <Hidden smUp>
                              <Typography className={classes.iconName1} noWrap>
                                {props?.data?.total_bed_rooms
                                  ? props?.data?.total_bed_rooms
                                  : "0"}
                              </Typography>
                            </Hidden> */}
                          </Box>
                        </Stack>
                        <Stack direction={"row"}>
                          <Box
                            className={classes.iconText}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <img src="/images/bathsIcon.svg" alt="bath" />
                            {/* <Hidden smDown> */}
                            <Typography className={classes.iconName1} noWrap>
                              {props?.data?.total_baths
                                ? props?.data?.total_baths + t("Baths")
                                : "0"+ t("Baths")}
                            </Typography>
                            {/* </Hidden> */}
                            {/* <Hidden smUp>
                              <Typography className={classes.iconName1} noWrap>
                                {props?.data?.total_baths
                                  ? props?.data?.total_baths
                                  : "0"}
                              </Typography>
                            </Hidden> */}
                          </Box>
                        </Stack>
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>
            <Box className={classes.cardTap}>
              <Grid container>
                <Grid item xs={6} sx={{ borderInlineEnd: "1px solid #E4E8EE", alignItems: "center" }}>
                  <Box
                    className={classes.iconText}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <img src="/images/unFurnished.svg" alt="furnished" />
                    <Typography className={classes.iconName1} noWrap>
                      {props?.data?.furnishing
                        ? props?.data?.furnishing
                        : props?.data?.furnishing_type}
                    </Typography>
                  </Box>
                  {/* <Typography textAlign={"center"} className={classes.iconName} noWrap>
                    {props?.data?.unit_purpose}
                  </Typography> */}
                </Grid>
                <Grid item xs={6} textAlign="center">
                  <Typography className={classes.iconName} noWrap>
                    {props?.data?.total_area
                      ? props?.data?.total_area + " " + props?.data?.uom_name
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Box>





            {/* <Box className={classes.cardTap}>
              <Grid container>
                <Grid item xs={4} sx={{ borderRight: "1px solid #E4E8EE" }}>
                  <Box
                    className={classes.iconText}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <img src="/images/unFurnished.svg" alt="furnished" />
                    <Typography className={classes.iconName} noWrap>
                      {props?.data?.furnishing
                        ? props?.data?.furnishing
                        : props?.data?.furnishing_type}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} sx={{ borderRight: "1px solid #E4E8EE" }}>
                  <Box
                    className={classes.iconText}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <img src="/images/bathsIcon.svg" alt="bath" />
                    <Typography className={classes.iconName}>
                      {props?.data?.total_baths
                        ? props?.data?.total_baths + " Baths"
                        : "0 Baths"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    className={classes.iconText}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <img src="/images/bedIcon.svg" alt="bed" />
                    <Typography className={classes.iconName}>
                      {props?.data?.total_bed_rooms
                        ? props?.data?.total_bed_rooms + " Beds"
                        : "0 Beds"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box> */}
          </Box>
        ) : (
          // <div
          //   className={classes.root}
          //   onClick={() => {
          //     props?.type === "unit"
          //       ? props?.onClick(props?.data)
          //       : history.push(
          //           Routes.requestDetails +
          //             "?requestId=" +
          //             props?.data?.id +
          //             "&requestNo=" +
          //             props?.data?.id +
          //             "&requestType=" +
          //             props?.data?.request_type +
          //             "&status=" +
          //             props?.data?.request_status
          //         );
          //   }}
          //   style={{ cursor: "pointer" }}
          // >
          //   <Box alignItems="center" className={classes.topCard}>
          //     <Box position={"relative"}>
          //       {props.type === "unit" ? (
          //         <>
          //           <img
          //             src={
          //               props?.data?.logo?.length > 0
          //                 ? props?.data?.logo
          //                 : "/images/unitplaceholder.svg"
          //             }
          //             className={classes.cardImage}
          //             alt="altimage"
          //           />
          //           <div className={classes.bottomCardUnit}>
          //             <Typography fontSize={"10px"}>
          //               {props?.data?.unit_no}
          //             </Typography>
          //           </div>
          //         </>
          //       ) : (
          //         <Box className={classes.relative}>
          //           <img
          //             src={
          //               props?.data?.image_url?.length > 0
          //                 ? props?.data?.image_url?.substring(
          //                     props?.data?.image_url?.lastIndexOf(".") + 1
          //                   ) !== "mp4"
          //                   ? props?.data?.image_url
          //                   : "/images/unitplaceholder.svg"
          //                 : "/images/unitplaceholder.svg"
          //             }
          //             className={classes.cardImage}
          //             alt="altimage"
          //           />
          //           <div className={classes.bottomText1}>
          //             {props?.data?.request_status === "Pending" && (
          //               <Typography
          //                 variant="subtitle2"
          //                 noWrap
          //                 className={classes.progress}
          //               >
          //                 In Progress
          //               </Typography>
          //             )}
          //             {props?.data?.request_status === "Closed" && (
          //               <Typography
          //                 variant="subtitle2"
          //                 className={classes.approved}
          //               >
          //                 Closed
          //               </Typography>
          //             )}
          //             {props?.data?.request_status === "Open" && (
          //               <Typography
          //                 variant="subtitle2"
          //                 className={classes.delete}
          //               >
          //                 Open
          //               </Typography>
          //             )}
          //             {props?.data?.request_status === "Cancelled" && (
          //               <Typography
          //                 variant="subtitle2"
          //                 className={classes.delete}
          //               >
          //                 Cancelled
          //               </Typography>
          //             )}
          //           </div>
          //         </Box>
          //       )}
          //     </Box>
          //     <Box flexGrow={1} marginLeft={"10px"}>
          //       <Typography className={classes.title}>
          //         {props?.type === "unit"
          //           ? props?.data?.unit_name
          //           : props?.data?.request_type}
          //         &nbsp;
          //         {props?.data?.unit_category && (
          //           <span className={classes.categoryTitle}>
          //             {props?.data?.unit_category}
          //           </span>
          //         )}
          //         {props?.type === "request" && (
          //           <Typography className={classes.bottomText}>
          //             {props?.data?.request_no?.slice(0, 13)}
          //           </Typography>
          //         )}
          //       </Typography>
          //       <Box height="10px" />

          //       <Box alignItems="center" display={"flex"}>
          //         <>
          //           <Typography className={classes.subTitle2}>
          //             {props?.type === "unit"
          //               ? props?.data?.property_name
          //               : props?.data?.units?.length > 1
          //               ? props?.data?.units?.length.toString() + "Units"
          //               : props?.data?.units?.unit_no}
          //           </Typography>
          //         </>
          //         {props?.type === "unit" && (
          //           <>
          //             {props?.data?.block_name && (
          //               <Box className={classes.periodStyle} />
          //             )}
          //             <Typography className={classes.subTitle2}>
          //               {props?.data?.block_name}
          //             </Typography>
          //           </>
          //         )}
          //         {props?.type === "unit" && (
          //           <>
          //             {props?.data?.floor_name && (
          //               <Box className={classes.periodStyle} />
          //             )}
          //             <Typography className={classes.subTitle2}>
          //               {props?.data?.floor_name}
          //             </Typography>
          //           </>
          //         )}
          //       </Box>
          //       {props?.type === "unit" && (
          //         <>
          //           <Box height="10px" />
          //           <Box alignItems="center" display={"flex"}>
          //             <Typography className={classes.subTitle2}>
          //               {props?.data?.unit_type_name ?? ""}
          //             </Typography>
          //             <Box className={classes.periodStyle} />
          //             <Typography className={classes.subTitle2}>
          //               {props?.data?.total_area &&
          //                 props?.data?.total_area + " " + props?.data?.uom_name}
          //             </Typography>
          //           </Box>
          //           <Box height="10px" />
          //           <Box marginLeft={"-10px"}>
          //             <Divider />
          //           </Box>
          //           <Box height="10px" />
          //           <Box alignItems="center" display={"flex"}>
          //             <Typography className={classes.subTitle}>
          //               {props?.data?.total_bed_rooms
          //                 ? props?.data?.total_bed_rooms + "Beds"
          //                 : "0"}
          //             </Typography>
          //             <Box className={classes.periodStyle} />

          //             <Typography className={classes.subTitle}>
          //               {props?.data?.total_baths
          //                 ? props?.data?.total_baths + "Baths"
          //                 : "0"}
          //             </Typography>
          //             <Box className={classes.periodStyle} />
          //             <Typography className={classes.subTitle}>
          //               {props?.data?.furnishing
          //                 ? props?.data?.furnishing
          //                 : props?.data?.furnishing_type}
          //             </Typography>
          //           </Box>
          //         </>
          //       )}
          //       {props?.type === "unit" && !props?.isUnit && (
          //         <>
          //           <Box height="8px" />
          //           <Typography className={classes.subTitle2}>
          //             {props?.data?.property_name}
          //           </Typography>
          //         </>
          //       )}
          //       {props?.type === "request" && (
          //         <>
          //           <Box
          //             container
          //             alignItems={"center"}
          //             direction={"row"}
          //             marginTop="10px"
          //           >
          //             <Typography className={classes.subTitle2}>
          //               {props?.data?.subject}
          //             </Typography>
          //           </Box>
          //           <Box
          //             alignItems={"center"}
          //             display={"flex"}
          //             marginTop="10px"
          //           >
          //             <img
          //               sx={{ padding: "10px" }}
          //               src={
          //                 props?.type === "request" &&
          //                 "/images/icons8_calendar.svg"
          //               }
          //               alt="altimage"
          //             />
          //             <Box width="8px" />
          //             <Typography className={classes.extraDetailsStyle}>
          //               {props?.type === "unit" ? (
          //                 <>{props?.data?.total_area} </>
          //               ) : (
          //                 props?.data?.raised_on
          //               )}
          //             </Typography>
          //           </Box>
          //         </>
          //       )}
          //     </Box>
          //   </Box>
          // </div>
          <UnitCard data={props} />
        ))}
    </div>
  );
};

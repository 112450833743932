import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Container, Dialog, Drawer, Hidden, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { SelectBox } from "../../components/dropDown";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods, removeDuplicatesByKey } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import Delivery from "./delivery";
import Parking from "./parking";
import Swiftinout from "./swiftinout";
import Visitors from "./visitors";
import { withBottombar } from "../../HOCs";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px"
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
  },
  bottomBarTitle: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  field: {
    marginTop: "12px"
  },
  submitBtn: {
    borderRadius: "4px",
    padding: "10px 8px",
    marginTop: "24px",
    boxShadow: "none",
  }
}));
const InitialState = {
  property: "",
  unit: "",
  type: "",
  error: {
    property: "",
    unit: "",
    type: "",
  },
};
const Entries = (props) => {
  const { t = () => false, handleTopBarState } = props;
  const agreement_id = JSON.stringify(localStorage.getItem(LocalStorageKeys?.agreement))?.id ?? "";
  const classes = useStyles();
  const size = useWindowDimensions();
  const [open, setOpen] = React.useState(false)
  const [property_unit_option, set_property_unit_option] = React.useState();
  const [formData, setFormData] = React.useState({ ...InitialState });
  const alert = React.useContext(AlertContext);
  const [search, setSearch] = React.useState({
    property: "",
    unit: "",
    type: "",
  })
  const backdrop = React.useContext(BackdropContext);
  const typeList = [
    {
      value: "VGR/WGR",
      label: "Visitor & Worker",
    },
    {
      value: "swift",
      label: "Swift in & Swift out"
    },
    {
      value: "PSR",
      label: "Parking slot"
    },
    {
      value: "DSR",
      label: "Delivery Collections"
    }
  ]
  const updateState = (key, value) => {
    let error = formData.error;
    error[key] = "";
    if (key === "property") {
      setFormData({ ...formData, [key]: value, unit: "",  error });
    } else {
      setFormData({ ...formData, [key]: value, error });      
    }
  };

  const getAggreement = (initial) => {
    NetworkCall(
      `${config.api_url}/agreement-unit-info/switcher`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    ).then((response) => {
      let agreementUnits = agreement_id
        ? response?.data?.data?.find((val) => val?.id === agreement_id)?.agreement_unit
        : response?.data?.data?.[0]?.agreement_unit;
      let temp_property_1 = agreementUnits?.length > 0 ? agreementUnits.map((agreement_unit) => {
        return {
          ...agreement_unit,
          label: agreement_unit?.property_name,
          value: agreement_unit?.property_id,
        }
      }) : []

      let temp_property_2 = removeDuplicatesByKey(temp_property_1, "value");

      let final_property = temp_property_2?.map?.((property) => {
        return {
          ...property,
          units: agreementUnits?.filter((agreement_unit_f) =>
            property?.value === agreement_unit_f?.property_id)
            ?.map((agreement_unit_m) => {
              return {
                ...agreement_unit_m,
                label: agreement_unit_m?.name,
                value: agreement_unit_m?.id
              }
            })
        }
      });

      set_property_unit_option(final_property);

      if (initial) {
        let initial_search = {
          property: final_property?.[0] ?? "",
          unit: final_property?.[0]?.units?.[0] ?? "",
          type: typeList?.[0] ?? "",
        }
        setFormData({
          ...initial_search,
          error: {
            property: "",
            unit: "",
            type: "",
          },
        })
        setSearch(initial_search)
        handleTopBarState({
          data: initial_search,
          text: t("EntryLogs"),
          entries: true,
          handleClick: () => setOpen(true)
        })
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      }
    })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  React.useEffect(() => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    getAggreement(true)
    // eslint-disable-next-line
  }, [])
  const isIamValide = () => {
    let isValid = true;
    let error = formData.error;
    //Checking unit
    if (formData.unit.length === 0) {
      isValid = false;
      error.unit = t("UnitisRequired");
    }
    //Checking property
    if (formData.property.length === 0) {
      isValid = false;
      error.property = t("PropertyisRequired");
    }
    //Checking name
    if (formData.type.length === 0) {
      isValid = false;
      error.type = t("TypeisRequired");
    }
    setFormData({ ...formData, error });
    return isValid;
  };
  const apply = () => {
    if (isIamValide()) {
      let temp_search = {
        property: formData.property,
        unit: formData.unit,
        type: formData.type,
      }
      setSearch(temp_search)
      handleTopBarState({
        data: temp_search,
        text: t("EntryLogs"),
        entries: true,
        handleClick: () => setOpen(true)
      })
      setOpen(false)
    } else {
      return false
    }
  }
  const renderSelectForm = () => {
    return (
      <div>
        <Box display="flex" alignItems="center" className={classes.titleroot} p={1}>
          <Box flexGrow={1}>
            <Typography variant="subtitle2" className={classes.bottomBarTitle}>{t("EntryLogs")}</Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setOpen(false)}><CloseIcon /></IconButton>
          </Box>
        </Box>
        <Box p={1} height="380px" overflow="auto">
          <div className={classes.field}>
            <SelectBox label={t("Property")} placeholder={t("SelectProperty")} options={property_unit_option}
              value={formData?.property}
              onChange={(value) => {
                updateState("property", value)
              }}
              isError={formData?.error?.property?.length > 0}
              errorMessage={formData?.error?.property}
            />
          </div>
          <div className={classes.field}>
            <SelectBox label={t("UnitID")} placeholder={t("SelectUnitID")} options={formData?.property?.units}
              value={formData?.unit}
              onChange={(value) => updateState("unit", value)}
              isError={formData?.error?.unit?.length > 0}
              errorMessage={formData?.error?.unit}
            />
          </div>
          <div className={classes.field}>
            <SelectBox label={t("EntryType")} placeholder={t("SelectEntryType")} options={typeList}
              value={formData?.type}
              onChange={(value) => updateState("type", value)}
              isError={formData?.error?.type?.length > 0}
              errorMessage={formData?.error?.type}
            />
          </div>
          <Button fullWidth className={classes.submitBtn} variant="contained" onClick={apply}>{t("Confirm")}</Button>
        </Box>
      </div>
    )
  }
  return (
    <Container maxWidth="sm" className={classes.root} style={{ height: size?.height }}>
      {/* <TitleBar data={search} text={t("EntryLogs")} goBack={() => history.goBack()} entries handleClick={() => setOpen(true)} /> */}
      <Box height={"50px"} />
      {search.type?.label === "Visitor & Worker" &&
        <Visitors open={open} units={search?.unit?.value} unitlist={formData?.property?.units} />
      }
      {search.type?.label === "Parking slot" &&
        <Parking units={[search.unit?.value]} unitlist={formData?.property?.units} />
      }
      {search.type?.label === "Delivery Collections" &&
        <Delivery units={[search.unit?.value]} unitlist={formData?.property?.units} />
      }
      {search.type?.label === "Swift in & Swift out" &&
        <Swiftinout
          units={search?.unit?.unit_id}
          unitlist={formData?.property?.units}
          property={formData?.property}
          unit={formData?.unit}
        />
      }
      <Hidden smDown>
        <Dialog className={classes.dialog} maxWidth="sm" fullWidth open={open} >
          {renderSelectForm()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)} className={classes.drawer}>
          {renderSelectForm()}
        </Drawer>
      </Hidden>
    </Container>
  );
};
const props = {
  top_bar: "title_bar"
}
export default withBottombar(withNamespaces("entriesLog")(Entries), props)
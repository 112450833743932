import { makeStyles } from "@mui/styles";
import { FontFamilySwitch, FontSizeCalc, remCalculate } from "../../utils";
import { nudges_card_constants } from "./constant";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.dashboard.primary,
    overflow: "hidden",
  },
  username: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  msg: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.primary,
  },
  invoiceContainer: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: theme.palette.borderRadius2,
  },
  invoiceTitle: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  invoiceTitleBlock: {
    backgroundColor: "#A1C9FF80",
    padding: "14px",
    borderTopRightRadius: theme.palette.borderRadius2,
    borderTopLeftRadius: theme.palette.borderRadius2,
  },
  invoice: {
    backgroundColor: "#FFF",
    padding: "14px",
    borderBottomRightRadius: theme.palette.borderRadius2,
    borderBottomLeftRadius: theme.palette.borderRadius2,
  },
  invoiceQuickPay: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.Tertiary,
    marginTop: "17px",
    marginBottom: "9px",
  },
  graph: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.palette.borderRadius2,
    boxShadow: "0px 3px 30px #5C86CB2E",
  },
  profileImg: {
    height: "50px",
    width: "50px",
    borderRadius: "50%",
    cursor: "pointer",
  },
  relative: {
    position: "relative",
    cursor: "pointer",
  },
  absolute: {
    position: "absolute",
    bottom: "4px",
    backgroundColor: "#091B29",
    borderRadius: "4px",
    color: "white",
    left: "0px",
    right: "0px",
    fontSize: "0.75rem",
    textAlign: "center",
    fontFamily: FontFamilySwitch().bold,
    display: "flex",
    justifyContent: "center",
    padding: "0px 5px",
  },
  closeIcon: {
    position: "absolute",
    top: "24px",
    right: "24px",
    cursor: "pointer",
  },
  backgroundImage: {
    objectFit: "cover",
    width: "100%",
  },
  rootmain: {
    "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
      margin: "10px",
    },
    width: "100%",
    maxWidth: "auto",
    padding: "0 !important",
    "& .MuiPaper-root": {
      width: "991px !important",
      borderRadius: theme.palette.borderRadius,
    },
    "& .MuiDialogContent-root": {
      padding: "0px !important",
      position: "relative",
      display: "flex",
    },
    "& .MuiDialog-container": {
      padding: (props) => props?.padding ?? "",
    },
  },
  welcome: {
    color: "#091B29",
    fontSize: "1.5rem",
    fontFamily: FontFamilySwitch().extraBold,
    position: "absolute",
    top: "62px",
    left: "23px",
  },
  name: {
    color: "#5078E1 !important",
  },
  referCard: {
    background: "white",
    borderRadius: theme.palette.borderRadius,
    padding: "14px 16px",
  },
  btn: {
    padding: "8px 12px",
    textTransform: "none",
    marginTop: "8px",
  },
  name2: {
    fontSize: "1.25rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  subName: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary,
  },
  box: {
    padding: "16px 16px 4px 16px",
  },
  invoice_action: {
    backgroundColor: "#F6F9FF",
    padding: "14px",
    borderTopLeftRadius: theme.palette.borderRadius,
    borderTopRightRadius: theme.palette.borderRadius,
    marginTop: "14px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderBottom: "1px solid #E4E8EE",
  },
  qrBox: {
    backgroundColor: theme.palette.background.secondary,
    padding: "32px"
  },
  whiteBox: {
    backgroundColor: "#ffffff",
    padding: "16px",
    borderRadius: "12px"
  },
  unitBox: {
    backgroundColor: theme.palette.background.background3,
    padding: "12px",
    borderRadius: "6px",
    marginTop: "12px"
  },
  unitAvatar: {
    backgroundColor: theme.palette.background.background2,
    height: "40px",
    width: "40px",
    marginInlineEnd: "12px"
  },
  round: {
    height: "6px",
    width: "6px",
    backgroundColor: theme.palette.background.background2,
    borderRadius: "50%"
  },
  house: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary
  },
  units: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.Tertiary
  },
  change: {
    color: theme.palette.primary.main,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
  },
  unitSwitcher: {
    border: "1px solid #CED3DD",
    padding: "0px 16px 0px 16px",
    borderRadius: "12px"
  },
  other_features_label: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme?.typography?.color?.primary,
    marginInlineStart: "12px"
  },
  other_features_card: {
    cursor: "pointer",
    borderRadius: theme?.palette?.borderRadius3,
    padding: "12px",
    backgroundColor: theme?.palette?.background?.tertiary1,
    boxShadow: "0px 3px 30px #4354721F",
  },
  nudge_card: {
    cursor: "pointer",
    minWidth: ({ size }) => size?.width >= 599 ? "568px" : `${size?.width - 32}px`,
    maxWidth: "568px",
    minHeight: ({ list }) => (list?.length === 1 && list?.[0]?.type === nudges_card_constants?.weather?.type) ? "80px" : "128px",
    borderRadius: theme?.palette?.borderRadius2,
    padding: "16px",
  },
  nudge_title_text: {
    fontSize: FontSizeCalc(12),
    fontFamily: FontFamilySwitch().regular,
    color: theme.palette.primary.contrastText,
    textShadow: "0px 0px 6px #00000029",
  },
  nudge_subject_text: {
    fontSize: FontSizeCalc(16),
    fontFamily: FontFamilySwitch().bold,
    color: theme.palette.primary.contrastText,
    textShadow: "0px 0px 6px #00000029",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
  nudge_CTA_text: {
    fontSize: FontSizeCalc(12),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.palette.primary.contrastText,
    textShadow: "0px 0px 6px #00000029",
  },
  nudge_pagnation_style: {
    borderRadius: "1px",
    width: "20px",
    maxWidth: "20px",
    height: "2px",
  },
  nudge_icon: {
    objectFit: "contain",
    height: "40px",
    width: "40px",
  },
  nudge_image: {
    objectFit: "contain",
    height: "40px",
    width: "40px",
    borderRadius: "50%",
  },
  featureCard: {
    backgroundColor: "#FFFFFF",
    padding: "12px",
    borderRadius: "8px",
    cursor: "pointer"
},
title: {
    marginInlineStart: "12px",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: remCalculate(14),
    color: theme?.typography?.color?.primary
},
quickLinkImg:{
  height: 28,
  width: 28,
  cursor: "pointer"
},
quickLinkLabel: {
  fontSize: "0.75rem",
  fontFamily: FontFamilySwitch().bold,
  color: theme.typography.color.primary,
  cursor: "pointer"
},
quickLinkBox:{
  background: "#fff",
  borderRadius: 12
}
}));
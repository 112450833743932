
import RingingScreen from "./ringingScreen";
import { useSocket } from "./socket";
import { useState, useEffect, useContext } from "react";
import { Container } from "@mui/material";
import { LocalStorageKeys, NetWorkCallMethods, AlertProps } from "../../utils";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Peer from 'peerjs';
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { LoadingSection } from "../../components";
import VideoContainer from "./videoCall";
import { Routes } from "../../router/routes";
import { AlertContext } from "../../contexts";

const VideoCall = ({ t = () => false }) => {


    const socket = useSocket();
    const history = useHistory();
    const searchURL = useLocation().search;
    const request_id = new URLSearchParams(searchURL).get("id");

    const [isvideo, setIsVideo] = useState(false);
    const alert = useContext(AlertContext);

    const [details, setDetails] = useState({
        data: null,
        loading: true
    });
    const [live_user, setLiveUser] = useState(false);
    const [seconds, setSeconds] = useState(60);
    const [peer, setPeer] = useState(null);
    const [partnerPeerId, setPartnerPeerId] = useState('');
    const [controls, setControls] = useState({
        audio: true
    })
    const [partnerControl, setPartnerControl] = useState({
        audio: false,
        video: false
    })
    const [partnerAccept, setPartnerAccept] = useState(false)


    const disconnect = () => {

        if (peer) {
            peer.destroy();
        }

        if (socket) {
            socket.emit('disconnect_trigger', localStorage.getItem(LocalStorageKeys.userProfileId), details?.data?.created_by);
        }

        history.push(Routes.dashboard)

    }

    useEffect(() => {

        if (socket && details?.data) {

            socket.emit('users', {
                token: localStorage.getItem(LocalStorageKeys?.authToken),
            });

            socket.on("user_connected", (data) => {

                console.log(data?.connected_users, 'user_connected');

                const isSubset = [details?.data?.created_by, localStorage.getItem(LocalStorageKeys.userProfileId)].every(value => data?.current_active_user?.includes(value));

                setLiveUser(isSubset ? true : false);

            })

            socket.on('accpet-resident', () => {
                setPartnerAccept(true)
            })

            socket.on("disconnect_trigger", () => {
                disconnect()
            })

        }
        // eslint-disable-next-line
    }, [socket, details]);

    useEffect(() => {

        if (request_id) {

            NetworkCall(
                `${config.api_url}/walkInout/details`,
                NetWorkCallMethods.post,
                { id: request_id },
                null,
                true,
                false
            ).then((res) => {
                setPartnerPeerId(res?.data?.created_by)

                setDetails({
                    data: res?.data,
                    loading: false
                })
            }).catch((err) => {
                console.log(err)
            })
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        const peerInstance = new Peer(localStorage.getItem(LocalStorageKeys.userProfileId), {
            key: "peerjs",
            debug: 2,
            secure: true
        });

        setPeer(peerInstance);

        peerInstance.on('open', (id) => {
            console.log('My peer ID is: ' + id);
        });

        peerInstance.on('call', (call) => {
            // Answer the call and send your stream
            const userMediaConstraints = {
                video: false,
                audio: controls?.audio,
            };

            navigator.mediaDevices
                .getUserMedia(userMediaConstraints)
                .then((stream) => {

                    call.answer(stream);
                    call.on('stream', (partnerStream) => {

                        const isVideoEnabled = partnerStream.getVideoTracks().length > 0;
                        const isAudioEnabled = partnerStream.getAudioTracks().length > 0;

                        setPartnerControl({
                            audio: isAudioEnabled,
                            video: isVideoEnabled
                        })

                        // Handle the partner's stream
                        const partnerVideo = document.getElementById('partner-video');
                        if (partnerStream) {
                            partnerVideo.srcObject = partnerStream;
                        }


                    });
                })
                .catch((error) => {
                    console.error('Error accessing user media:', error);
                });
        });

        return () => {
            // Clean up Peer.js when the component is unmounted
            if (peer && socket) {
                disconnect()
            }
        };


        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        let timer;

        if (seconds > 0 && !partnerAccept) {
            timer = setInterval(() => {
                setSeconds(seconds - 1);
            }, 1000);
        }

        // Cleanup the timer when the component unmounts or when seconds reach 0
        return () => clearInterval(timer);

        // eslint-disable-next-line
    }, [seconds]);

    useEffect(() => {

        if (seconds === 0 && isvideo) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.warning,
                msg: "Security Guard Not Responding",
            });

            disconnect()
        }

        // eslint-disable-next-line
    }, [seconds])



    const goBack = () => {
        if (live_user && !isvideo) {
            history.push(Routes.dashboard)
        } else {
            disconnect()
        }

    }

    const cancelCall = () => {
        disconnect()
    }

    const accessControl = (controls) => {

        setControls(controls)


        if (isvideo) {

            navigator.mediaDevices
                .getUserMedia(controls)
                .then((stream) => {
                    peer.call(partnerPeerId, stream);
                })
                .catch((error) => {
                    console.error('Error accessing user media:', error);
                });

        }
    }

    const changeStatusTrigger = (det) => {
        
        socket.emit('change_request_status', det, request_id);


        alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `You have ${det?.status === "Approved"?"approved":"denied"} ${details?.data?.person_name}'s entry`,
        });

    }



    return (
        <Container maxWidth="sm" style={{ padding: "0px" }}>
            {
                details?.loading ?
                    <LoadingSection controls={controls} message={"Loading"} top={"20vh"} />
                    :
                    <>

                        {isvideo ?
                            <VideoContainer  changeStatusTrigger={changeStatusTrigger} partnerAccept={partnerAccept} partnerControl={partnerControl} controls={controls} accessControl={accessControl} goBack={goBack} details={details?.data} cancelCall={cancelCall}/>
                            :
                            <RingingScreen changeStatusTrigger={changeStatusTrigger} setIsVideo={setIsVideo} controls={controls} accessControl={accessControl} live_user={live_user} cancelCall={cancelCall} goBack={goBack} details={details?.data} />
                        }
                    </>
            }

        </Container>

    )
}
export default VideoCall
import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.dashboard.primary,
      padding: "0px",
    },
    tabroot: {
      display: "flex",
      alignItems: "center",
      padding: "4px 8px 10px 12px",
      backgroundColor: theme.palette.background.secondary,
      marginTop: "8px",
    },
    tabItem: {
      textTransform: "capitalize",
      fontWeight: "bold",
      color: theme.typography.color.primary,
      padding: "0px",
      minHeight: "auto",
      // borderRadius: "20px",
      "& .MuiButtonBase-root-MuiTab-root": {
        // borderRadius: "20px",
      },
      minWidth: "auto",
  
      marginRight: "8px",
      // backgroundColor: "white",
    },
    tabItemSelect: {
      textTransform: "capitalize",
      fontWeight: "bold",
      color: theme.typography.color.primary,
      marginRight: "8px",
      padding: "0px",
      borderRadius: "4px",
      minHeight: "auto",
      "& .MuiButtonBase-root-MuiTab-root": {
        minHeight: "0px",
      },
      minWidth: "auto",
    },
    screenScrollEffect: {
      overflow: "overlay",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    info: {
      fontSize: "1.125rem",
      fontFamily: FontFamilySwitch().extraBold,
      textAlign: "center",
    },
    title: {
      padding: "8px 14px",
      // background: "#5078E1",
      color: "#071741",
      display: "flex",
      alignItems: "center",
    },
    titleselect: {
      padding: "8px 14px",
      borderRadius: "4px",
      backgroundColor: "#071741",
      display: "flex",
      alignItems: "center",
      color: "white",
    },
    search: {
      [`& fieldset`]: {
        borderRadius: "4px",
        height: 50,
        border: "1px solid #CED3DD",
        "& :hover": {
          border: "1px solid #5078E1",
        },
      },
      "& .MuiOutlinedInput-input": {
        padding: "11px 14px",
      },
    },
    titles: {
      fontSize: "1rem",
      fontFamily: FontFamilySwitch().extraBold,
      margin: "2px 8px 8px 8px",
    },
    box: {
      padding: "16px 0px",
    },
    agreement: {
      backgroundColor: "#FFFFFF",
      borderRadius: "8px",
      padding: "4px 8px",
      cursor: "pointer",
      border: "1px solid #E4E8EE",
    },
    exporie: {
      backgroundColor: "#5078E114",
      padding: "2px 4px",
      borderRadius: "4px",
      color: theme.palette.primary.main,
      fontSize: "0.75rem",
      fontFamily: FontFamilySwitch().bold,
    },
    id: {
      color: theme.typography.color.primary,
      fontFamily: FontFamilySwitch().extraBold,
      fontSize: "0.875rem",
    },
    frequentlyUsed: {
      borderRadius: theme.palette.borderRadius2,
      backgroundColor: "#fff",
      padding: "12px 15px 18px 15px",
      boxShadow: "0px 3px 30px #4354721F",
    },
    amenities: {
      height: "186px",
      background: "linear-gradient(297deg, #03C8A8, #89D8D3)",
      borderRadius: theme.palette.borderRadius2,
      boxShadow: "0px 3px 30px #5C86CB2E",
      padding: "16px 0px 16px 16px",
      position: "relative",
    },
    amenitiesFixed: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "124px",
      height: "85%",
      position: "absolute",
      top: "16px",
      left: "16px",
    },
    amenitiesSmText: {
      color: "#298477",
      fontFamily: FontFamilySwitch().semiBold,
      fontSize: "0.75rem",
    },
    amenitiesTitle: {
      color: "#ffffff",
      fontFamily: FontFamilySwitch().extraBold,
      fontSize: "1.25rem",
    },
    amenitiesViewAll: {
      color: "#298477",
      fontFamily: FontFamilySwitch().bold,
      fontSize: "0.75rem",
      cursor:"pointer"
    },
    amenitiesScroller: {
      position: "relative",
      zIndex: "1000",
      height: "100%",
    },
    bottomToggle: {
      "& .MuiPaper-root": {
        borderRadius: "16px 16px 0 0",
        padding: "15px",
      },
    },
    quickLinkImg: {
      height: 28,
      width: 28,
      cursor: "pointer"
    },
    quickLinkLabel: {
      fontSize: "0.75rem",
      fontFamily: FontFamilySwitch().bold,
      color: theme.typography.color.primary,
      cursor: "pointer"
    },
    quickLinkBox: {
      background: "#fff",
      borderRadius: 12
    }
  }));
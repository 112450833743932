/* eslint-disable array-callback-return */
import {
  Box,
  Container,
  Grid,
  Typography,
  Stack
} from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import CommitteeIcon from "../../assets/commiteeIcon";
import ContactMeIcon from "../../assets/contactMe";
import PlaceOfInterestIcon from "../../assets/placeOfInterest";
import YourTownshipIcon from "../../assets/yourTownship";
import {
  CarouselSliders,
  DialogDrawer,
  NoService,
} from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils/constants";
import useWindowDimensions from "../../utils/useWindowDimensions";
import UnitDetails from "../agreement/component/UnitDetails";
// import AmenitiesScroller from "./components/AmenitiesScroller";
// import { ArrowRightOutline } from "../../assets/community/ArrowRightOutline";
// import { Notifications } from "../../assets/community/Notifications";
import ServiceCard from "../../components/serviceCard";
import { getAccess } from "../../utils";
import AmenityBookingNew from "../amenityBookingNew/amenityBookingNew";
import CommunityMemberModel from "./components/CommunityMemberModel";
import { useStyles } from "./styles";
import { Routes } from "../../router/routes";
import { useHistory } from "react-router-dom";

const Service = ({ props, t = () => false, i18n, handleTopBarState, theme }) => {
  const size = useWindowDimensions();
  const [banners, setBanners] = React.useState([]);
  const classes = useStyles(theme);
  const value = "1";
  const search = "";
  const [toggleDrawerBottom, setToggleDrawerBottom] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const app = config?.app_x_build === "PG-TE-08" ? "Tenant2" : "Tenant"
  const [noService, setNoService] = React.useState(false)
  const history = useHistory()

  const logo = JSON.parse(localStorage.getItem("accessType"))?.filter(
    (val) => val?.name === app
  );
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const openAmenity = () => {
    setOpen(true)
  }
  const getBanners = () => {
    let data = {
      type: value === "1" ? "Community" : "Services",
      tenantId: `${config.tenantid}`,
      company_id: logo?.map((val) => val?.company_id),
      offset: 0,
      limit: 1000,
    };
    NetworkCall(
      `${config.api_url}/company-banner/list`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    ).then((response) => {
      let bannerss = response.data.data.map((item) => item?.assets).flat();
      setBanners(bannerss);
    });
  };

  const homeCard = [
    {
      id: "1",
      img: "/images/homeCleanIMG.svg",
      title: "Home",
      sub: "Cleaning",
      link: "/service",
      filter: "Cleaning",
      noavailable: true,
      borderColor: "#8C20E5",
    },
    {
      id: "2",
      img: "/images/homeSanitization.svg",
      title: "Home",
      sub: "Sanitization",
      link: "/service",
      noavailable: true,
      filter: "Home Sanitization",
      borderColor: "#F7A72F",
    },
    {
      id: "3",
      img: "/images/bathrommCleanIMG.svg",
      noavailable: true,
      title: "Bathroom",
      sub: "Cleaning",
      link: "/service",
      filter: "Bathroom Cleaning",
      borderColor: "#50E6FF",
    },
    {
      id: "4",
      img: "/images/tempIMG.svg",
      title: "Home ",
      noavailable: true,
      sub: "Repair",
      link: "/service",
      filter: "Home Repair",
      borderColor: "#199BE2",
    },
    {
      id: "5",
      img: "/images/homePaintingIMG.svg",
      noavailable: true,
      title: "Home",
      sub: "Painting",
      link: "/service",
      filter: "Home Painting",
      borderColor: "#8C20E5",
    },
  ];

  const frequentlyUsedData = [
    {
      label_1: t("visitor"),
      label_2: t("Pass"),
      icon_url: "/images/dashboardIcons/visitor_icon.svg",
      link: "/visitor_gate_pass",
      nextLine: t("pass"),
      show: true,
    },
    {
      label_1: t("Worker"),
      label_2: t("permit"),
      icon_url: "/images/dashboardIcons/worker_icon.svg",
      link: "/worker_gate_pass",
      show: true,
    },
    {
      label_1: t("Amenity"),
      label_2: t("Booking"),
      icon_url: "/images/dashboardIcons/amenities_icon.svg",
      link: openAmenity,
      type: "method",
      nextLine: t("Booking"),
      show: getAccess('is_amenity_booking'),
    },
    {
      label_1: t("guest"),
      label_2: t("parking"),
      icon_url: "/images/dashboardIcons/parking_icon.svg",
      link: "/parking_pass",
      show: true,
    },
    {
      label_1: t("registered"),
      label_2: t("Worker"),
      icon_url: "/images/dashboardIcons/registered_icon.svg",
      link: "/domestic_help",
      show: true,
    },
    {
      label_1: t("Delivery &"),
      label_2: t("Parcels"),
      icon_url: "/images/dashboardIcons/delivery_icon.svg",
      link: "/delivery",
      show: getAccess('is_delivery_collection'),
    },
  ];
  const CommunityCorner = [
    // {
    //   name: "walk_ins",
    //   svg: <WalkIn />,
    //   link: "/service",
    // },
    // {
    //   name: "walk_ins",
    //   svg: <WalkIn />,
    //   link: "/service",
    //   noavailable: true,
    //   is_false:getAccess('is_walk_in_track') === false ? true : false
    // },
    {
      name: t("polls"),
      svg: "images/pollsIcon.svg",
      link: "/service",
      noavailable: true,
      show: getAccess('is_polls'),
      is_image: true
    },
    {
      name: t("survey"),
      svg: "images/surveyIcon.svg",
      link: "/service",
      noavailable: true,
      show: getAccess('is_survey'),
      is_image: true
    },
    // {
    //   name: "notifications",
    //   svg: <Notifications />,
    //   link: "/notification",
    // },
    {
      name: t("Dicussion Group"),
      svg: "images/discussionIcon.svg",
      link: "/community_discussion",
      show: getAccess('is_discussion_forum'),
      is_image: true
    },
    {
      name: t("Committee Members"),
      svg: "images/managementTeam.svg",
      // sub: "team",
      link: "/managment",
      is_image: true,
      show: true,
    },
    {
      name: t("Emergency Number"),
      svg: "images/emergencyIcon.svg",
      link: "/emergency_contact",
      is_image: true,
      show: true,
    },
  ];

  // essentials data
  const EssentialsData = [
    {
      id: "1",
      img: "/images/carRentalIMG.svg",
      title: "Car",
      sub: "Rental",
      link: "/service",
      filter: "Car Rental",
      noavailable: true,
      borderColor: "#388FE2",
    },
    {
      id: "2",
      img: "/images/postalResitentIMG.svg",
      title: "Postal",
      sub: " Assistance",
      link: "/service",
      filter: "Postal Assistance",
      noavailable: true,
      borderColor: "#007AD9",
    },
    {
      id: "3",
      img: "/images/mediaclResidentIMG.svg",
      title: "Medical",
      sub: "Supplies",
      link: "/service",
      filter: "Medical Supplies",
      noavailable: true,
      borderColor: "#D4943B",
    },
    {
      id: "4",
      img: "/images/pastIMG.svg",
      title: "Pest",
      sub: "Control",
      link: "/service",
      filter: "Pest Control",
      noavailable: true,
      borderColor: "#F09600",
    },
    {
      id: "5",
      img: "/images/packerIMG.svg",
      title: "Packer &",
      sub: "Movers",
      link: "/service",
      noavailable: true,
      filter: "Packer Movers",
      borderColor: "#FAD500",
    },
    {
      id: "6",
      img: "/images/legalIMG.svg",
      noavailable: true,
      title: "Legal",
      sub: "Assistance",
      link: "/service",
      filter: "Legal Assistance",
      borderColor: "#50E6FF",
    },
    {
      id: "7",
      img: "/images/carWashIMG.svg",
      title: "Car",
      sub: "Wash",
      link: "/service",
      noavailable: true,
      filter: "Car Wash",
      borderColor: "#388FE2",
    },
  ];

  //homeproduct data
  const Homeproduct = [
    {
      id: "1",
      img: "/images/sweetIMG.svg",
      title: "Sweets &",
      link: "/service",
      sub: "Savories",
      search: "Sweets Savories",
      noavailable: true,
      borderColor: "#F09600",
    },
    {
      id: "2",
      img: "/images/orgaincIMG.svg",
      title: "Organic",
      link: "/service",
      sub: "Products",
      search: "Organic Products",
      noavailable: true,
      borderColor: "#FAD500",
    },
    {
      id: "3",
      img: "/images/foodIMG.svg",
      title: "Food",
      noavailable: true,
      link: "/service",
      sub: "Supplies",
      search: "Food Supplies",
      borderColor: "#C966E9",
    },
  ];

  //township data tab 3
  const TownshipData = [
    {
      id: "1",
      icon: <YourTownshipIcon />,
      title: "Your",
      sub: "Township",
      link: "/service",
      filter: "Your Township",
      noavailable: true,
      borderColor: "#388FE2",
    },
    {
      id: "2",
      img: "/images/announcementIMG.svg",
      title: "Public",
      sub: "Announcements",
      link: "/service",
      filter: "Public Announcements",
      noavailable: true,
      borderColor: "#199BE2",
    },
    {
      id: "3",
      icon: <ContactMeIcon />,
      title: "Contact",
      sub: "Township",
      link: "/service",
      filter: "Contact Township",
      noavailable: true,
      borderColor: "#388FE2",
    },
    {
      id: "4",
      img: "/images/aminitiesAgree.svg",
      title: "Public",
      sub: "Amenities",
      link: "/service",
      filter: "Public Amenities ",
      noavailable: true,
      borderColor: "#C966E9",
    },
    {
      id: "5",
      icon: <PlaceOfInterestIcon />,
      title: "Places Of",
      sub: "Interest",
      link: "/service",
      noavailable: true,
      filter: "Places Of Interest",
      borderColor: "#388FE2",
    },
    // {
    //   id: "6",
    //   img: "/images/legalIMG.svg",
    //   noavailable: true,
    //   title: "Enroll",
    //   sub: "Residents",
    //   link: "/service",
    //   filter: "Enroll Residents",
    //   borderColor: "#50E6FF"

    // },
    {
      id: "7",
      icon: <CommitteeIcon />,
      title: "Township",
      sub: "Committee",
      link: "/service",
      noavailable: true,
      filter: "Township Committee",
      borderColor: "#388FE2",
    },
  ];

  //   const data = [{
  //     name: "Swimming Pool"
  //   },
  //   {
  //     name: "Table Tennis"
  //   },
  //   {
  //     name: "Badminton"
  //   },
  // ]
  React.useEffect(() => {
    handleTopBarState({
      t: t,
      note: true,
      i18n: i18n,
      // new: main?.unread,
    })
    getBanners();
    // eslint-disable-next-line
  }, [value]);

  const quickLinkClick = (item) => {
    if (item?.onClick) {
      item?.onClick()
    }
    if (item?.noavailable) {
      setNoService(true);
    } else if (item?.type === "method") {
      item?.link();
    }
    else if ([Routes?.amenities, Routes?.requestScreen, Routes?.units].includes(item?.link)) {
      history.push(item?.link + "?Id=" + item?.data);
    } else {
      history.push({
        pathname: item?.link,
        state: {
          dashboard: true,
          main: {
            agreement: item?.data,
          },
        },
      });
    }
  }

  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TopNavBars 
       t={t}
       i18n={i18n}
        note={true}
        count="4"
        handleClicknote={() => history.push(Routes.notification)}
        document={() => history.push(Routes?.fileManager)}
      /> */}
      <Grid
        container
        className={classes.screenScrollEffect}
        style={{ height: size?.height - 138, overflow: "auto" }}
      >
        <Grid item xs={12} sx={{ padding: "12px" }}>
          <Grid container>

            {value === "2" && (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.titles}>
                      {t("HomeCare")}
                    </Typography>
                  </Grid>
                  {homeCard
                    ?.filter((movie) => {
                      if (search === "") {
                        return movie;
                      } else if (
                        movie?.title
                          ?.toLowerCase()
                          ?.includes(search?.toLowerCase())
                      ) {
                        return movie;
                      }
                    })
                    ?.map((val, index) => {
                      return (
                        <Grid key={index} item xs={4}>
                          <ServiceCard data={val} />
                        </Grid>
                      );
                    })}
                </Grid>
                <Grid item xs={12} sx={{ margin: "12px 0px 0px 0px" }}>
                  <CarouselSliders autoScroll={true} data={banners} />
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box height="4px" />
                    <Typography className={classes.titles}>
                      {t("Essentials")}
                    </Typography>
                  </Grid>
                  {EssentialsData?.filter((movie) => {
                    if (search === "") {
                      return movie;
                    } else if (
                      movie?.title
                        ?.toLowerCase()
                        ?.includes(search?.toLowerCase())
                    ) {
                      return movie;
                    }
                  })?.map((val, index) => {
                    return (
                      <Grid key={index} item xs={4}>
                        <ServiceCard data={val} noavailable />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box height="12px" />
                    <Typography className={classes.titles}>
                      {t("Homeproduct")}
                    </Typography>
                  </Grid>
                  {Homeproduct?.filter((movie) => {
                    if (search === "") {
                      return movie;
                    } else if (
                      movie?.title
                        ?.toLowerCase()
                        ?.includes(search?.toLowerCase())
                    ) {
                      return movie;
                    }
                  })?.map((val, index) => {
                    return (
                      <Grid key={index} item xs={4}>
                        <ServiceCard data={val} noavailable />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
            {value === "1" && (
              <Grid item xs={12}>
                <Grid item xs={12} sx={{ margin: "12px 0px 25px 0px" }}>
                  <CarouselSliders autoScroll={true} data={banners} />
                </Grid>
                <Typography className={classes.titles}>
                  {t("Quick Links")}
                </Typography>
                <Box sx={{ height: "12px" }} />
                {/* <IconButtonCard openDrawer={() => { setToggleDrawerBottom(true) }} data={frequentlyUsedData} t={t} /> */}
                <Box className={classes.quickLinkBox}>
                  <Grid container spacing={2} py={"12px"}>
                    {
                      frequentlyUsedData?.map((data) => {
                        return (
                          data?.show &&
                          <Grid item xs={3}>
                            <Stack alignItems={"center"} rowGap={1} onClick={() => quickLinkClick(data)}>
                              <img src={data?.icon_url} alt="" className={classes.quickLinkImg} />
                              <Typography className={classes.quickLinkLabel}>{data?.label_1}</Typography>
                            </Stack>
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </Box>
                <Box height={"16px"} />
                <Box className={classes.frequentlyUsed}>
                  <Typography className={classes.titles}>
                    {t("CommunityCorner")}
                  </Typography>
                  <Box sx={{ marginTop: "13px" }}>
                    <UnitDetails openDrawer={() => { setToggleDrawerBottom(true) }} data={CommunityCorner} t={t} icon_bg={"#D9e6FE80"} />
                  </Box>
                </Box>
                <Box height={"20px"} />
                {/* <Grid container>
                  <Grid item xs={12}>
                    <Box className={classes.amenities}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box className={classes.amenitiesFixed}>
                            <div>
                              <Typography className={classes.amenitiesSmText}>
                                {t("Relax")} &#8226; {t("Play")} &#8226;{" "}
                                {t("Enjoy")}
                              </Typography>
                            </div>
                            <div>
                              <Typography className={classes.amenitiesTitle}>
                                {t("Theamenitiesforyou")}
                              </Typography>
                            </div>
                            <div>
                              <Typography className={classes.amenitiesViewAll} onClick={()=> setOpen(true)}>
                                {t("ViewAll")}{" "}
                                <ArrowRightOutline color="#298477" />
                              </Typography>
                            </div>
                          </Box>
                          <Box className={classes.amenitiesScroller}>
                              <AmenitiesScroller setOpen={setOpen} data={data} />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid> */}
                <DialogDrawer
                  open={toggleDrawerBottom}
                  onClose={() => setToggleDrawerBottom(false)}
                  onOpen={() => setToggleDrawerBottom(true)}
                  isnotTitle={true}
                  component={
                    <CommunityMemberModel
                      t={t}
                      onClose={() => setToggleDrawerBottom(false)}
                    />
                  }
                />
              </Grid>
            )}
            {value === "3" && (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {TownshipData?.filter((movie) => {
                    if (search === "") {
                      return movie;
                    } else if (
                      movie?.title
                        ?.toLowerCase()
                        ?.includes(search?.toLowerCase())
                    ) {
                      return movie;
                    }
                  })?.map((val, index) => {
                    return (
                      <Grid key={index} item xs={4}>
                        <ServiceCard data={val} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <DialogDrawer
        height={"300px"}
        maxWidth={"sm"}
        open={open}
        header={" "}
        // noService={true}
        component={<AmenityBookingNew closeServicePopup={() => setOpen(false)} />}
        onClose={() => setOpen(false)} />
      <DialogDrawer
        height={"300px"}
        maxWidth={"sm"}
        open={noService}
        header={" "}
        noService={true}
        drawerComponent={
          <>
            <NoService
              main={t("This feature is either not enabled or not opted by your organization.")}
              sub={t("Please contact your administrator for details.")}
              buttonName={t("Close")}
              mobile
              fullWidth
              onClose={() => setNoService(false)}
            />
          </>
        }
        dialogComponent={
          <>
            <NoService
              main={t("This feature is either not enabled or not opted by your organization.")}
              sub={t("Please contact your administrator for details.")}
              buttonName={t("Close")}
              fullWidth
              onClose={() => setNoService(false)}
            />
          </>
        }
        onClose={() => setNoService(false)}
      />
    </Container>
  );
};
export default withNamespaces("service")(Service);
import SendIcon from "@mui/icons-material/Send";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { ADD_LIKE, UPDATELIKE } from "../../graphql/mutations";
import { GET_THREAD } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, FontFamilySwitch, generateImageUrl, img_size, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
import { UploadBtn } from "./components/uploadBtn";
import { AssetCard } from "./components/assetCard";
import { CommentCard } from "./components/commentCard";
import { withBottombar } from "../../HOCs";


import ImageViewer from "react-simple-image-viewer";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F6F6F6",
    padding: "0px",
  },
  commentSection: {
    padding: "12px 14px",
  },
  Cardcontent: {
    backgroundColor: "white",
    padding: "12px 14px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px 6px 6px 0px",
  },
  title: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
    marginTop: "8px",
  },
  sendRoot: {
    backgroundColor: "white",
    border: "2px solid #CED3DD",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    boxShadow: "0px -6px 12px #00000021",
  },
  send: {
    backgroundColor: "#5078E1",
    borderRadius: "4px",
    color: "white",
    "&:hover": {
      backgroundColor: "#5078E1",
      borderRadius: "4px",
      color: "white",
    },
  },
  name: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  unit: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().regular,
  },
  time: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().regular,
    textTransform: "capitalize"
  },
  dis: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "4px",
  },
  titlecontainer: {
    paddingBottom: "12px",
  },
  like: {
    fontSize: "0.75rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  property: {
    fontSize: "0.75rem",
    color: "#5D427F",
    backgroundColor: "#F1E6FE",
    padding: "2px",
    borderRadius: "4px",
    fontFamily: FontFamilySwitch().bold,
    display: "inline-block"
  },
  new: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    backgroundColor: "#FF4B4B",
    padding: "2px",
    borderRadius: "4px",
    fontFamily: FontFamilySwitch().bold,
  },
  comment: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().bold,
    marginTop: "4px",
  },
  replay: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().regular,
    marginLeft: "4px",
    marginTop: "4px",
  },
  replay1: {
    fontSize: "0.75rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().regular,

  },
  replaySection: {
    padding: "8px 12px",
  },
  communityBox: {
    flexFlow: "wrap !important",
  },
  message: {
    fontSize: "0.875rem",
    color: theme.typography.color.Tertiary,
  },
  messageNot: {
    fontSize: "0.875rem",
    color: "white",
  },
  textField: {
    backgroundColor: "white",
    border: "none",
    [`& fieldset`]: {
      border: "none",
    },
  },
  btnRoot: {
    position: "absolute",
    bottom: 0,
    maxWidth: "600px",
    width: "100%",
  },
  detailImg2: {
    borderRadius: theme.palette.borderRadius,
    height: "80px",
    width: "100%",
    objectFit: "cover",
  },
  addImg: {
    position: "relative",
    backgroundColor: "#504e4e3d",
    borderRadius: "4px",
    height: "80px",
    cursor: "pointer",
  },
  plus: {
    position: "absolute",
    textAlign: "center",
    left: "0px",
    right: "0px",
    top: "30%",
    color: "white",
    fontFamily: FontFamilySwitch().semiBold

  },
}));
//referenceId
const DiscussionDetails = (props) => {
  const { t = () => false, handleTopBarState } = props;
  const classes = useStyles(props);
  const [allow, setAllow] = React.useState(true);
  const [comment, setComment] = React.useState("");
  const size = useWindowDimensions();
  const search = useLocation().search;
  const [comments, setComments] = React.useState([]);
  const discussionId = new URLSearchParams(search).get("discussionId");
  const [details, setDetails] = React.useState([]);
  const [text, setText] = React.useState(false);
  const [offSet, setOffSet] = React.useState(0);
  const alert = React.useContext(AlertContext);
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState(0);
  const [currentViewer, setCurrentViewer] = React.useState();

  const openImageViewer = React.useCallback((index, viewer) => {
    setCurrentImage(index);
    setCurrentViewer(viewer);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const getDiscussionDetails = () => {
    const data = {
      query: GET_THREAD,
      variables: {
        threadId: discussionId,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setDetails(response?.data?.data?.community_discussion);
        setAllow(true);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing Went wrong."),
        });
      });
  };
  const getComments = (offset, filter) => {
    const data = {
      tenantId: `${config.tenantid}`,
      discussion_id: discussionId,
      offset: offset,
      limit: 20,
    };
    NetworkCall(
      `${config.api_url}/community/comments`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter) {
          setComments(response?.data?.data);
        } else {
          setComments(comments?.concat(response?.data?.data));
        }
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing Went wrong."),
        });
      });
  };
  React.useEffect(() => {
    handleTopBarState({
      text: "Community Discussion"
    })
    getDiscussionDetails();
    getComments(0);
    // eslint-disable-next-line
  }, []);

  const Liked = () => {
    const adddata = {
      query: ADD_LIKE,
      variables: {
        like: [
          {
            community_discussion: discussionId,
            created_by: localStorage.getItem(LocalStorageKeys.userProfileId),
            updated_by: localStorage.getItem(LocalStorageKeys.userProfileId),
            is_active: true,
            client: JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id
          },
        ],
      },
    };

    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      adddata,
      null,
      true,
      false
    )
      .then((response) => {
        getDiscussionDetails();
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing Went wrong."),
        });
      });
  };

  const DisLiked = (val1) => {
    const deletedata = {
      query: UPDATELIKE,
      variables: {
        id: val1,
        updatedData: {
          is_active: false,
        },
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      deletedata,
      null,
      true,
      false
    )
      .then((response) => {
        getDiscussionDetails();
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing Went wrong."),
        });
      });
  };
  const addLike = async (val1, val2, val3) => {
    setAllow(false);
    if (allow) {
      if (val3) {
        await DisLiked(val1, val2, val3);
      } else {
        await Liked(val1, val2, val3);
      }
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setText(false);
    setAssets([]);
    if (comment.length > 0) {
      const data = {
        discussion: discussionId,
        comment: comment,
        assets: assets?.length > 0 ? JSON.stringify(assets) : null
      }
      NetworkCall(
        `${config.api_url}/community/community_comment`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          setComment("");
          getComments(0, true);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some thing Went wrong.",)
          });
        });
    }
  };
  const fetchMoreData = () => {
    setOffSet(offSet + 20);
    getComments(offSet + 20);
  };
  const [assets, setAssets] = React.useState([]);

  //upload image
  const handleUpload = (value) => {
    setAssets([...assets, value]);
  };
  //delete asset
  const onDelete = (i) => {
    const edit = assets?.filter((value, index) => index !== i);
    setAssets(edit);
  };
  return (
    <>
      <Container className={classes.root} maxWidth="sm">
        {/* <TitleBar text={t("CommunityDiscussion")} goBack={goBack} /> */}

        <div
          style={{
            height: size?.height - 120,
            overflow: "auto",
            position: "relative",
          }}
        >
          <div className={classes.Cardcontent}>
            <Box alignItems="center" display="flex">
              <Box>
                <Avatar src={generateImageUrl(details?.[0]?.created_by?.get_assets_url_id, img_size.small_square)} />
              </Box>
              <Box flexGrow={1} sx={{ marginInlineStart: "10px" }}>
                <Box
                  className={classes.communityBox}
                  alignItems="center"
                  display="flex"
                >
                  {/* <Box> */}
                  <Typography variant="subtitle2" className={classes.name}>
                    {details?.[0]?.created_by?.first_name}&nbsp;
                    {details?.[0]?.created_by?.last_name}
                  </Typography>
                  {/* </Box> */}
                  <Box className={classes.dot} />
                  <Box flexGrow={1} noWrap>
                    <Typography
                      variant="subtitle2"
                      className={classes.unit}
                      noWrap
                    >
                      {details?.[0]?.unit?.name}
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.time}
                    noWrap
                  >
                    {t("Postedat")}&nbsp;
                    {moment(new Date(details?.[0]?.created_at)).fromNow()}
                  </Typography>
                </Box>

                {details?.[0]?.property?.name && (
                  <Typography className={classes.property} noWrap>
                    &nbsp;#{details?.[0]?.property?.name}&nbsp;
                  </Typography>
                )}
              </Box>
            </Box>
            <div className={classes.titlecontainer}>
              <Typography variant="subtitle2" className={classes.title}>
                {details?.[0]?.title}
              </Typography>
              <Typography variant="subtitle2" className={classes.dis}>
                {(details?.[0]?.description && details?.[0]?.description?.length > 0) && (details?.[0]?.description?.split('\n').map((item, i) => <Typography variant="subtitle2" key={i} className={classes.dis}>{item}</Typography>))}
              </Typography>
              {details?.[0]?.assets?.length > 0 && (
                <Grid container spacing={1} marginTop={0.7}>
                  {
                    details?.[0]?.assets?.map((v, i) => {
                      return (
                        <Grid item xs={3} onClick={() => openImageViewer(i, "main")}>
                          <img
                            src={generateImageUrl(v?.url, img_size.small_square) ?? ""}
                            alt=""
                            className={classes.detailImg2}
                          />
                        </Grid>
                      )
                    })
                  }
                </Grid>
              )}
            </div>

            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  marginTop="10px"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    addLike(
                      details?.[0]?.community_discussion_reactions?.[0]?.id,
                      details?.[0]?.community_discussion_reactions?.[0]
                        ?.created_by,
                      details?.[0]?.community_discussion_reactions?.[0]
                    )
                  }
                >
                  <Box>
                    {details?.[0]?.community_discussion_reactions?.map(
                      (val) => val?.created_by
                    )[0] === localStorage.getItem("userProfileId") ? (
                      <img src="images/icons8-facebook-like (1).svg" alt="" />
                    ) : (
                      <img src="/images/icons8-facebook-like.svg" alt="" />
                    )}
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" className={classes.like}>
                      {details?.[0]?.community_discussion_reactions?.map(
                        (val) => val.created_by
                      )[0] === localStorage.getItem("userProfileId") ? (
                        <span style={{ color: "#5078E1" }}>
                          &nbsp;
                          {
                            details?.[0]?.community_discussion_reactions?.length
                          }{" "}
                          {t("Likes")}
                        </span>
                      ) : (
                        <>
                          &nbsp;
                          {
                            details?.[0]?.community_discussion_reactions?.length
                          }{" "}
                          {t("Likes")}
                        </>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>

          <div className={classes.commentSection}>
            <Typography variant="subtitle2" className={classes.comment}>
              {comments?.length} {t("Comments")}
            </Typography>

            <div>
              {comments?.length > 0 && (
                <>
                  <InfiniteScroll
                    dataLength={comments.length}
                    next={fetchMoreData}
                    hasMore={true}
                    height={size.height - 330}
                  >
                    {comments?.map((val) => {

                      return (
                        <CommentCard
                          val={val}
                          t={t}
                          comment={details?.[0]}
                        />
                      );
                    })}
                  </InfiniteScroll>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={classes.btnRoot}>
          <Box alignItems="center" p={1} className={classes.sendRoot}>
            <form onSubmit={handleSubmit}>
              {(text || assets?.length > 0) && (
                <>
                  <TextField
                    size="small"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Add comment"
                    fullWidth
                    multiline
                    rows={4}
                    className={classes.textField}
                    autoFocus
                  />
                  <Grid container spacing={2}>
                    {assets?.length > 0 &&
                      assets?.map((val, index) => {
                        return (
                          <Grid item xs={2.5}>
                            <AssetCard
                              // disable={disable}
                              onDelete={() => onDelete(index)}
                              x={val}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                  <Divider />
                </>
              )}
            </form>
            <Box alignItems="center" p={1} display="flex">
              <Box>
                <UploadBtn
                  buttonName=""
                  handleChange={handleUpload}
                  iconButton
                />
              </Box>
              <Box flexGrow={1} marginLeft="10px" marginTop="4px">
                <Typography
                  className={text ? classes.messageNot : classes.message}
                  onClick={() => setText(!text)}
                >
                  {t("Write Message here")}
                </Typography>
              </Box>
              <Box marginLeft="4px">
                <IconButton
                  disabled={comment.length > 0 ? false : true}
                  onClick={handleSubmit}
                  className={classes.send}
                >
                  <SendIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </div>
        {isViewerOpen && (
          <ImageViewer
            src={
              currentViewer === "main"
                ? details?.[0]?.assets
                  ?.filter((i) => i?.is_active)
                  ?.map((val) => val?.url)
                : [currentViewer?.url]
            }
            currentIndex={currentImage}
            onClose={closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
            closeOnClickOutside={true}
          />
        )}
      </Container>
    </>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("communityDiscussion")(DiscussionDetails), props);

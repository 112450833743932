import { Avatar, Box, Divider, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import DateIcon from "../../assets/dateIcon";
import { Routes } from "../../router/routes";
import { FontFamilySwitch, generateImageUrl, img_size } from "../../utils";

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: theme.palette.background.paper,
    margin: "auto 4px",
    padding: "12px",
    boxShadow: "0px 3px 30px #5C86CB2E"
  },
  title: {
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  progress: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#78B1FE",
    padding: "1px 8px",
    // borderRadius: "4px",
  },
  approved: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
  },
  delete: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
  },
  cancel: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular,
  },
  img: {
    borderRadius: theme.palette.borderRadius,
    height: "120px",
    width: "101px"
  },
  received: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#EC903F",
    padding: "1px 8px",
  },
  rejected: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#EC903F",
    padding: "1px 8px",
  },
  boxes: {
    flexFlow: 'wrap !important'
  },
  avatar: {
    height: "70px",
    width: "70px",
    objectFit: "cover",
    // marginInlineEnd: "16px"
  },
  bold: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary,
    marginInlineStart: "8px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.75rem"
    },
  },
  semiBold: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,

  },
  bottomText: {
    bottom: 0,
    borderRadius: "4px",
    backgroundColor: "#071741",
    color: "white",
    width: "110px",
    padding: "4px",
    textAlign: "center"
  },
  bottomText1: {
    position: "absolute",
    bottom: "0px",
    color: "white",
    textAlign: "center",
    right: "0px",
    left: "0px"
  },
  collected: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
  },
  bottomCard: {
    backgroundColor: "#fff",
    borderRadius: "0px 0px 8px 8px",
    padding: "10px 14px",
    margin: "auto 4px",
    borderTop: "1px solid #E4E8EE"
  }
}));

export const DeliveryCard = (props) => {
  const { t=()=>false } = props
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <Box sx={{ position: "relative" }}>
      <Stack direction={"row"} className={classes.card}
        onClick={() =>
          history.push(Routes.deliveryDetails + "?passId=" + props?.data?.id)
        }
      >
        <Box style={{ position: "relative" }}>
          {props?.data?.vehicle_image ?
            <img src={generateImageUrl(props?.data?.vehicle_image, img_size.small_square)} alt="" className={classes.avatar} /> :
            <Avatar className={classes.avatar} src={
              props?.parking
                ? "/images/delivery_icon.svg"
                : "/images/workergate.svg"
            } variant="square" />
          }
          <Box position={"absolute"} className={classes.bottomText1}>
            {props?.data?.delivery_status === "Yet To Receive" && (
              <Typography
                variant="subtitle2"
                noWrap
                className={classes.progress}
              >
                {t("Yet To Receive")}
              </Typography>
            )}

            {props?.data?.delivery_status === "Collected" && (
              <Typography
                variant="subtitle2"
                className={classes.collected}
                style={{ backgroundColor: "#5AC782" }}
              >
                {t("Collected")}
              </Typography>
            )}
            {props?.data?.delivery_status === "Received At Gate" && (
              <>
                <Typography
                  variant="subtitle2"
                  noWrap
                  className={classes.received}
                >
                  {t("Received")}
                </Typography>
              </>
            )}
            {props?.data?.delivery_status === "Cancelled" && (
              <Typography variant="subtitle2" className={classes.cancel}>
                {t("Cancelled")}
              </Typography>
            )}
          </Box>
        </Box>
        <Stack sx={{ marginInlineStart: "16px" }} direction={"column"} spacing={"6px"}>
          <Typography noWrap className={classes.title}>
            {props?.data?.description}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.sub}
            noWrap
          >
            {props?.data?.property_name}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.sub}
            noWrap
          >
            {props?.data?.unit?.[0]?.name}
          </Typography>
        </Stack>
      </Stack>
      <Stack justifyContent={"space-around"} className={classes.bottomCard} direction="row" divider={<Divider orientation="vertical" flexItem />}  spacing={1} alignItems="center">
        <Stack direction="row" pt={"2px"}>
          <DateIcon />
          <Typography
            variant="subtitle2"
            className={classes.bold}
            noWrap
          >
          {props?.data?.delivery_collected_date ? `${t("Collected Date")}-` : `${t("Request On")}-`}
          </Typography>

          <Typography
            variant="subtitle2"
            className={classes.bold}
            noWrap
          >
            &#x202a;{moment(props?.data?.delivery_collected_date ?? props?.data?.request_on)
              .tz(moment.tz.guess())
              .format("DD MMM YY ")}&#x202c;
          </Typography>
        </Stack>
        <Box display={"flex"} alignItems="center">
          <img src="/images/unitss.svg" alt="" />
          <Typography
            variant="subtitle2"
            className={classes.bold}
            noWrap
          >
            {props?.data?.gate}
          </Typography>
        </Box>
      </Stack >
    </Box >
  );
};


import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch, FontSizeCalc, remCalculate } from "../../utils";
export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px"
  },
  expenseButton: {
    color: "#fff",
    padding: "10px 130px",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
    borderRadius: "8px",
    backgroundColor: "#5078E1",
    textTransform: "initial"
  },
  content: {
    padding: "16px",
    borderRadius: "12px",
    width: "100%"
  },
  bottomBarTitle: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
    textTransform:"capitalize"
  },
  title: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary,
  },
  productTitle:{
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "4px 8px 8px 8px",
  },
  requsettop: {
    backgroundColor: "#5078E11E",
    padding: "4px",
    borderRadius: "4px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  requsettitle: {
    marginTop: "10px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
  },
  requsetsub: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().regular
  },
  requsetsubs: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  requsetsubs2: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    marginTop: "4px",
    display: "flex",
    alignItems: "center",
    fontFamily: FontFamilySwitch().semiBold
  },
  trackLabel: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    marginTop: "4px",
    display: "flex",
    alignItems: "center",
    marginInlineEnd: "8px"
  },
  trackLabel2: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    marginTop: "4px",
    display: "flex",
    alignItems: "center",
    marginInlineStart: "8px"
  },
  mobile: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    marginTop: "4px",
    display: "flex",
    alignItems: "center",
    direction: "ltr",
    fontFamily: FontFamilySwitch().semiBold
    // marginInlineEnd: "8px"
  },
  alternateMobile: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    marginTop: "4px",
    display: "flex",
    alignItems: "center",
    marginInlineStart: "8px"
  },
  requsetsubs3: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    marginTop: "4px",
    alignSelf: "end",
  },
  avatar: {
    backgroundColor: "#E4E8EE",
  },
  pa: {
    color: theme.typography.color.primary,
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #5078E1",
    backgroundColor: "white",
    borderRadius: "4px",
    textTransform:"capitalize"
  },
  BOMstatusBox: {
    borderRadius: theme.palette.borderRadius,
    fontSize: '0.75rem',
    fontFamily: FontFamilySwitch().semiBold,
    color: "#FFFFFF",
    padding: "5px"
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  submitbtn: {
    border: "1px solid #5078E1",
    borderRadius: "4px",
    padding: "12px",
  },
  convayTitle: {
    color: theme?.palette?.border?.status,
    fontSize: "0.9375rem",
    fontFamily: FontFamilySwitch().bold,
  },
  status: {
    color: `${theme?.typography?.color?.Tertiary} !important`,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  subStatus: {
    color: theme?.palette?.border?.status,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  statusBox: {
    borderRadius: "4px",
    backgroundColor: theme?.palette?.info?.light,
    padding: "16px"
  },
  notesTitle: {
    color: theme?.typography?.color?.primary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  seeMoreLessTextStyle: {
    fontSize: "0.75rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
},
  createnewsectionlog1: {
    position: "relative",
    padding: "14px 20px 30px 26px",
    maxHeight: `calc(75vh - 400px)`,
    overflow: "auto",
    // backgroundColor: "white",
  },
  dateTime: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#7C8594",
    textAlign: "-webkit-match-parent",
    direction: "ltr"
  },
  innerImage: {
    height: "100%",
    width: "100%",
    backgroundColor: theme?.typography?.status?.primary,
  },
  notesTimeLine: {
    color: theme?.palette?.background?.primary,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold
  },
  noteTimeLine: {
    color: theme?.typography?.color?.secondary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    maxWidth: "624px",
    width: '100%',
    textAlign: "left"
  },
  border: {
    borderInlineStart: `2px dashed ${theme?.palette?.primary?.main}`,
    padding: "0px 40px",
    position: "relative",
    // "&:last-child": {
    //     borderLeft: "none",
    // },
  },
  avatars1: {
    position: "absolute",
    top: 0,
    left: '-22px',
    height: "45px",
    width: "45px",
    backgroundColor: "#F2F4F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px"
  },
  image: {
    backgroundColor: theme?.palette?.primary?.main,
    borderRadius: "50%",
    padding: "10px 9px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  recentNote: {
    fontSize: "0.9375rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme?.typography?.color?.primary,
  },
  imageStyleView: {
    height: "100%",
    width: "100%",
    margin: '0 auto',
    objectFit: "cover"
  },
  divider: {
    backgroundColor: "#E4E8EE",
    height: "1px"
  },
  imageStyle: {
    height: "75px",
    width: "75px",
    borderRadius: "4px",
    margin: "0px 16px 0px 0px",
    objectFit: "cover"
  },
  notes: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.typography?.color?.secondary,
  },
  overflow: {
    display: "flex",
    alignItems: "center",
    overflow: "auto !important"
  },
  costingBox: {
    backgroundColor: "#F2F4F7",
    borderRadius: "4px",
    padding: "9px 16px"
  },
  label: {
    fontSize: "0.875rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold
  },
  total: {
    color: "#4E5A6B",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold
  },
  cost: {
    color: "#091B29",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold
  },
  reject: {
    backgroundColor: "#FF4B4B",
    borderRadius: "20px",
    color: "#fff",
    padding: "10px 47px",
    cursor: "pointer",
    height: "40px",
    "&:hover": {
      backgroundColor: "#FF4B4B",
    }
  },
  accept: {
    backgroundColor: "#5AC782",
    borderRadius: "20px",
    color: "#fff",
    padding: "10px 45px",
    cursor: "pointer",
    height: "40px",
    "&:hover": {
      backgroundColor: "#5AC782",
    }
  },
  text: {
    color: "#091B29",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold
  },
  whiteBox: {
    borderRadius: "12px",
    backgroundColor: "#fff",
    marginBottom: "16px"
  },
  lineOne: {
    color: "#98A0AC",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold
  },
  lineTwo: {
    color: "#091B29",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold
  },
  add: {
    color: "#5078E1",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer"
  },
  button: {
    color: "#FFFFFF",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    padding: "15px 149px",
    backgroundColor: "#5078E1",
    borderRadius: "8px"
  },
  payBackground: {
    backgroundColor: "#fff",
    boxShadow: "0px -1px 6px #00000021"
  },
  payButton: {
    color: "#FFFFFF",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    padding: "15px 34px",
    backgroundColor: "#5078E1",
    borderRadius: "8px"
  },
  amount: {
    color: "#091B29",
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().bold
  },
  actualExpense: {
    color: "#98A0AC",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular
  },
  blueBackground: {
    backgroundColor: "#F1F7FF",
    padding: "12px",
    borderRadius: "4px"
  },
  instext: {
    fontSize: "0.875rem",
    color: "#78B1FE",
    fontFamily: FontFamilySwitch().semiBold,
  },
  arrowIcon: {
    color: "#78B1FE",
    marginTop: "3px",
  },
  addSpending: {
    color: "#4E5A6B",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular
  },
  update: {
    color: "#5078E1",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.75rem",
    cursor: "pointer"
  },
  expenseBox: {
    backgroundColor: "#F2F4F7",
    width: "100%",
    padding: "11px 8px",
    borderRadius: "8px"
  },
  currency: {
    color: "#A4B2C1",
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.875rem",
    marginLeft: "6px"
  },
  notesHeight: {
    maxHeight: "120px",
    overflow: "scroll"
  },
  productBox: {
    border: "1px solid #CED3DD",
    borderRadius: "8px"
  },
  inspectionItemIcon: {
    backgroundColor: "#F5F7FA",
    border: "1px solid #E4E8EE",
    padding: "4px",
    borderRadius: "6px"
  },
  productDetails: {
    fontSize: remCalculate(12),
    fontFamily: FontFamilySwitch()?.semiBold,
    color: theme.typography.color.primary,
    [theme.breakpoints.only("xs")]: {
      maxWidth:"90px"
    },
  },
  productDetailsTitle: {
    fontSize: remCalculate(12),
    fontFamily: FontFamilySwitch()?.regular,
    color: theme.typography.color.secondary
  },
  subTitle: {
    fontSize: remCalculate(12),
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.secondary
  },
  itemCondition: {
    fontSize: remCalculate(12),
    color: "#5AC782",
    fontFamily: FontFamilySwitch()?.semiBold,
  },
  itemBox: {
    backgroundColor: "#EEF9EE",
    borderRadius: theme.palette.borderRadius,
    display: "flex",
    justifyContent: "center",
    width: "100px",
    padding: "5px"
  },
  itemName: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().regular,
    textOverflow:"ellipsis",
    overflow:"hidden",
    [theme.breakpoints.only("xs")]: {
      maxWidth:"90px"
    },
    [theme.breakpoints.up("xs")]: {
      maxWidth:"150px"
    },
  },
  requestNo: {
    fontSize: "0.875rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular
  },
  BillingAmountTxt: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.tertiary
  },
  finalamttext:{
    color:theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
  },
  viewDetailsTxt: {
    color: "#5078E1",
    fontFamily:FontFamilySwitch().semiBold ,
    fontSize: "0.875rem",
    cursor:"pointer"
  },
  addNote: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#5078E1",
    cursor: "pointer"
  },
  notesBox: {
    border: "1px solid #CED3DD",
    // padding:"16px",
    borderRadius: theme.palette.borderRadius
  },
  notesLabel: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold
  },
  removeTxt: {
    fontSize: "0.875rem",
    color: "#FF4B4B",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer"
  },
  fixBottom: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding:"16px"
  },
  requestDetailsBox: {
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius,
    padding: "8px"
  },
  rejectbtn: {
    backgroundColor: "#FFECEC",
    border:"1px solid #FF4B4B",
    borderRadius:"8px",
    color:"#FF4B4B",
    fontSize:"0.75rem",
    boxShadow:"none",
    fontFamily:FontFamilySwitch().bold,
    "&:hover": {
      backgroundColor: "#FFECEC",
      border:"1px solid #FF4B4B",
    },
    textTransform:"capitalize"
  },
  reschedulebtn:{
    backgroundColor: "#F1F7FF",
    border:"1px solid #5078E1",
    borderRadius:"8px",
    color:"#5078E1",
    fontSize:"0.75rem",
    boxShadow:"none",
    fontFamily:FontFamilySwitch().bold,
    "&:hover": {
      backgroundColor: "#F1F7FF",
      border:"1px solid #5078E1",
    },
    textTransform:"capitalize"
  },
  approvebtn: {
    backgroundColor: "#EEF9EE",
    border:"1px solid #5AC782",
    borderRadius:"8px",
    fontSize:"0.75rem",
    boxShadow:"none",
    fontFamily:FontFamilySwitch()?.bold,
    color:"#5AC782",
    "&:hover": {
      backgroundColor: "#EEF9EE",
    },
    textTransform:"capitalize"
  },
  nonAssetBox: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #CED3DD",
    borderRadius: "8px",
  },
  verticalborder:{
    borderInlineStart:"2px dashed #CED3DD",
    margin:"-20px 12px",
    height:"55px"
  },
  kpibox:{
    borderRadius:"25px",
    padding:"4px 8px",
    // opacity:"15%"
  },
  subLine:{
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold
  },
  totaltimeBox:{
    borderRadius:"12px",
    border:"1px solid #CED3DD"
  },
  timeTaken:{
    color:"#33691E",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold
  },
  requestPendingBox:{
    borderRadius:"12px",
    backgroundColor:"#FFFFFF",
    boxShadow:"0px 3px 30px #4354721F"
  },
  subText: {
    color:theme.typography.color.primary,
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.75rem"
  },
  btnfix:{
    position:"absolute",
    bottom:0,
    left:0,
    right:0,
    padding:"16px",
    border:"1px solid #E4E8EE"
  },
  dotted: {
    border: "1px dashed #CED3DD"
  },
  reschedulebox:{
    borderRadius:"8px",
    backgroundColor:"#F5F7FA",
    padding:"16px"
  },
  applyBoxShadow:{
    boxShadow:"0px 8px 24px #0717411F"
  },
  costingStatusText:{
    color:"#FFFFFF",
    padding:"4px 12px",
    borderRadius:"25px",
    fontFamily:FontFamilySwitch()?.semiBold,
    fontSize:"0.75rem"
  },
  nudge_card: {
    cursor: "pointer",
    borderRadius: "12px",
    padding: "16px",
    boxshadow: "0px 3px 30px #4354721F",
    backgroundColor: "#FFFFFF",
    marginBottom: "16px"
  },
  invoice_total_amount: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: FontSizeCalc(14),
    color: "#091B29",
  },
  invoice_count: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: FontSizeCalc(12),
    color: "#4E5A6B",
  },
  pay_now_button: {
    border: "none",
    borderRadius: "8px",
    backgroundColor: "#F1F7FF",
    "&:hover": {
      backgroundColor: "#F1F7FF",
    },
    fontFamily: FontFamilySwitch().bold,
    fontSize: FontSizeCalc(12),
    color: "#5078E1",
    textTransform: "capitalize",
  },
}));

export const previewStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F6F6F6"
  },
  summaryBox: {
    backgroundColor: "#fff",
    padding: "12px",
    borderRadius: "12px"
  },
  previewBox: {
    backgroundColor: "#F5F7FA",
    borderRadius: "8px",
    padding: "16px"
  },
  divider: {
    backgroundColor: "#E4E8EE",
    height: "1px"
  },
  dotted: {
    border: "1px dashed #CED3DD"
  },
  bigDivider: {
    border: "3px solid #F2F4F7"
  },
  problem: {
    fontSize: "0.875rem",
    color: "#091B29",
    fontFamily: FontFamilySwitch().bold
  },
  label: {
    color: "#4E5A6B",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular
  },
  totalCost: {
    color: "#091B29",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold
  },
  individualCost: {
    color: "#4E5A6B",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold
  },
  description: {
    color: "#091B29",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold
  },
  billSummary: {
    color: "#091B29",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold
  },
  continueBox: {
    backgroundColor: "#fff",
    padding: "16px"
  },
  continueButton: {
    borderRadius: "8px",
    padding: "12px 143px",
    color: "#fff",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    backgroundColor: "#5078E1"
  },
  location: {
    color: "#091B29",
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.75rem"
  },
  recentNote: {
    fontSize: "0.9375rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme?.typography?.color?.primary,
  },
}));
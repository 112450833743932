import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { FontFamilySwitch, generateImageUrl, img_size } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "8px",
    margin: "0px 4px 0px 8px",
    [theme.breakpoints.down("xs")]: {
      padding: "5px",
      margin: "0px 0px 0px 5px",

    },
  },
  roots: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 3px 30px #5C86CB2E",
    backgroundColor: "#FFFFFF",
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  name: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  des: {
    color: '#4E5A6B',
    fontSize:"0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    marginLeft: '4px'
  },
  call: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
  },
  mail: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
  },
  tagStyle: {
    backgroundColor: "#F1E6FE",
    borderRadius: "4px",
    padding: "4px 6px 4px 6px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#5D427F",
    textTransform: "uppercase",
    marginLeft: "8px",
  },

  avatar: {
    backgroundColor: "#F0F4FF",
    width: "60px",
    height: "60px",
  },
  horizontalDividerStyle: {
    backgroundColor: "#E4E8EE",
    height: "1px",
  },
  bottomCard: {
    padding: "12px",
    alignItems:"center"
  },
  extraDetailsStyle: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
  },
  verticalDividerStyle: {
    backgroundColor: "#E4E8EE",
    width: "1px",
    height: "20px",
  },
  img: {
    objectFit: "cover"
  },
  roleName: {
    backgroundColor: "#5078E1",
    padding: "5px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().Bold,
    color: "#FFFFFF",
    borderRadius: theme.palette.borderRadius
  }
}));

const ManagementList = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.roots}>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" className={classes.bottomCard}>
                <Box mr={"12px"}>
                  <Avatar
                    className={classes.avatar}
                    src={generateImageUrl(props?.data?.resident_profileByID?.image_url, img_size.small_square) ?? ""}
                  />
                </Box>
                {/* <Box marginTop="6px" marginLeft="8px"> */}
                <Stack>
                  {props?.data?.committee_role?.role_name?.length > 0 && (
                    <>
                      <Typography className={classes.roleName} noWrap>
                        {props?.data?.committee_role?.role_name}
                      </Typography>
                      <Box mt={0.5}>
                        <Typography className={classes.name}>
                          {props?.data?.resident_profileByID?.first_name}
                        </Typography>
                        <Typography className={classes.name}>
                          {props?.data?.unitByID?.name}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Stack>
                {/* <Box display="flex" style={{ flexFlow: "wrap" }}>
                        {props?.data?.resident_profileByID
                          ?.mobile_no_country_code?.length > 0 && (
                          <>
                            <Box display="flex" alignItems={"center"}>
                              <Box className={classes.periodStyle} />
                              <Typography className={classes.mail} noWrap>
                                {
                                  props?.data?.resident_profileByID
                                    ?.mobile_no_country_code
                                }{" "}
                                {props?.data?.resident_profileByID?.mobile_no}
                              </Typography>
                            </Box>
                          </>
                        )}

                        {props?.data?.resident_profileByID?.email_id?.length >
                          0 && (
                          <>
                            <Box display="flex" alignItems={"center"}>
                              <Box className={classes.periodStyle} />
                              <Typography className={classes.mail} noWrap>
                                {props?.data?.resident_profileByID?.email_id}
                              </Typography>
                            </Box>
                          </>
                        )}
                      </Box> */}
              </Box>
              {/* </Box> */}
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.horizontalDividerStyle} />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                className={classes.bottomCard}
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid
                  container
                  sm={4}
                  xs={4}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <a
                    href={`mailto:${props?.data?.resident_profileByID?.email_id}`}
                    style={{ textDecorationLine: "none" }}
                  >
                    <Box display="flex" alignItems="center">
                      <img
                        sx={{ padding: "10px", cursor: "pointer" }}
                        src="/images/maintancemail.svg"
                        alt="altimage"
                      />

                      <Box width="8px" />

                      <Typography className={classes.extraDetailsStyle}>
                        {props?.data?.resident_profileByID?.email_id}
                      </Typography>
                    </Box>
                  </a>
                </Grid>
                <Box className={classes.verticalDividerStyle} />
                <Grid
                  container
                  sm={4}
                  xs={4}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <a
                    href={`tel:${props?.data?.resident_profileByID?.mobile_no_country_code}+${props?.data?.resident_profileByID?.mobile_no}`}
                    style={{ textDecorationLine: "none", color: "#071741" }}
                  >
                    <Box display="flex" alignItems="center">
                      <img
                        sx={{ cursor: "pointer" }}
                        src="/images/maintancecall.svg"
                        alt="altimage"
                      />

                      <Box width="8px" />
                      <Typography className={classes.extraDetailsStyle}>
                        {props?.data?.resident_profileByID?.mobile_no_country_code}{" "}{props?.data?.resident_profileByID?.mobile_no}
                      </Typography>
                    </Box>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default withNamespaces("management")(ManagementList)

import {
      Box,
      Button,
      Container,
      Grid,
      Stack,
      Typography
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../config";
import { AlertContext, BackdropContext, FirebaseContext } from "../../contexts";
// import { updateDeviceToken } from "../../graphql/mutations";
// import { NetworkCall } from "../../networkcall";
import { BackendRoutes, Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, RetainLocalStorage, roles, SetDeviceToken } from '../../utils';
import useWindowDimensions from "../../utils/useWindowDimensions";
import { CompanyList } from './component/companyselect';
import { companySelectUseStyles } from "./style";
import { withNamespaces } from "react-i18next";
import jwt_decode from "jwt-decode";
import { TitleBarNew } from "../../components/titlebarNew";
import { initital_screen_state } from "../../utils/login/utils";
import { NetworkCall } from "../../networkcall";
const CompanySelect = ({ t = () => false }) => {
      let temp_login_screen_state = localStorage?.getItem(LocalStorageKeys?.login_screen_state);
      let login_screen_state = JSON?.parse(temp_login_screen_state);
      // useDimension
      const size = useWindowDimensions();
      // classes
      const classes = companySelectUseStyles({ size });
      const { state } = useLocation();
      // history
      const history = useHistory()
      const alert = React.useContext(AlertContext);
      const backdrop = React.useContext(BackdropContext);
      // state
      const [client, setClient] = React.useState(state?.[0] ?? "")
      const [disable, setDisable] = React.useState(false)
      const firebaseContext = React.useContext(FirebaseContext);
      const [screen_state, set_screen_state] = useState(login_screen_state ?? initital_screen_state);
      const device = localStorage.getItem("device_type");

      useEffect(() => {
            initialLoad();
            // eslint-disable-next-line
      }, []);

      const initialLoad = () => {
            if (login_screen_state) {
                  if (window.location.origin === login_screen_state?.domain) {
                  } else {
                        getInitialRender();
                  }
            } else {
                  getInitialRender();
            }
      }

      const getInitialRender = () => {
            NetworkCall(
                  `${config?.auth_api_url}${BackendRoutes?.login_domain_initial_render}`,
                  NetWorkCallMethods?.post, {}, null, false, false)
                  .then((res) => {
                        let is_custom_domain = res?.data?.data?.is_custom_domain;
                        let _ = res?.data?.data?.render_data?.[0]
                        if (_) {
                              let temp_screen_state = {
                                    ...screen_state,
                                    [_?.id && "id"]: _?.id,
                                    [_?.region && "region"]: _?.region,
                                    [_?.login_logo && "logo"]: _?.login_logo,
                                    [_?.web_background && "web_background"]: _?.web_background,
                                    [_?.mobile_background && "mob_background"]: _?.mobile_background,
                                    [_?.web_welcome && "web_welcome_background"]: _?.web_welcome,
                                    [_?.mobile_welcome && "mob_welcome_background"]: _?.mobile_welcome,
                                    [_?.flag && "flag"]: _?.flag,
                                    [_?.domain && "domain"]: _?.domain,
                                    [is_custom_domain && "is_custom_domain"]: is_custom_domain,
                              }
                              set_screen_state(temp_screen_state);
                              localStorage.setItem(LocalStorageKeys?.login_screen_state, JSON?.stringify(temp_screen_state));
                        }
                  }).catch((error) => {
                        alert.setSnack({
                              ...alert, open: true,
                              severity: AlertProps.severity.error,
                              msg: t("Some Thing Went Wrong"),
                        });
                  });
      }
      React.useEffect(() => {
            if (localStorage.getItem("userProfileId")) {
                  history.push(Routes.dashboard)
            } else {
                  backdrop.setBackDrop({ ...backdrop, open: true, message: "", });
                  if (client?.id) {
                        if (state?.length === 1) {
                              submit()
                        } else {
                              backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                        }
                  } else {
                        backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                  }
            }
            // eslint-disable-next-line
      }, [client?.id]);
      // add client
      const companySelect = (client) => {
            setClient(client)
      }
      //get client
      const getClientId = (roleID) => {
            axios.get(`${config.auth_api_url}/auth/select_role?role_id=${roleID}`,
                  { headers: { Authorization: `Bearer ${localStorage.getItem(LocalStorageKeys.authToken)}`, "x-build-code": config.app_x_build } }
            ).then((res) => {
                  localStorage.setItem(LocalStorageKeys.authToken, res.data.token)
                  localStorage?.setItem?.(LocalStorageKeys?.user_id, jwt_decode(res?.data?.token)?.id);
                  localStorage.setItem(LocalStorageKeys.client, JSON.stringify(res?.data?.selected_client))
                  setDisable(false)
                  if (localStorage.getItem(LocalStorageKeys.deviceToken) && res?.data?.userProfile?.[0]?.id) {
                        SetDeviceToken(localStorage.getItem(LocalStorageKeys.deviceToken), res?.data?.userProfile?.[0]?.id, res?.data?.selected_client?.id,device)
                  }
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                  history.push({
                        pathname: Routes.dashboard,
                        state: true
                  })
            }).catch((err) => {
                  if (err.message === "Request failed with status code 403") {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: t("You currently don't have any live agreements"),
                        });
                        RetainLocalStorage();
                        return history.push(Routes.login);
                  }
                  else if (err?.response?.data?.status === 423) {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: err?.response?.data?.message,
                        });
                  }
                  else {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: t("SomethingWentwrong"),
                        });
                        backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                        setDisable(false)
                  }
            });
      }
      //submit
      const submit = () => {
            setDisable(true)
            axios.get(`${config.auth_api_url}/auth/select_client?client_id=${client?.id}`,
                  { headers: { Authorization: `Bearer ${localStorage.getItem(LocalStorageKeys.authToken)}` } }
            ).then((res) => {

                  const rolesName = res?.data?.userProfile?.[0]?.roles.map((val) => { return val.name });


                  const isTenant = config?.app_x_build === "PG-TE-08" ? "Tenant2" : "Tenant"

                  if (rolesName.includes(isTenant)) {
                        console.log("tenant", rolesName)
                        //set datas in local
                        let role = res?.data?.userProfile?.[0]?.roles?.filter((val) => val.name === (config?.app_x_build === "PG-TE-08" ? roles.tenant2 : roles.tenant));
                        localStorage.setItem(LocalStorageKeys.authToken, res.data.token)
                        localStorage?.setItem?.(LocalStorageKeys?.user_id, jwt_decode(res?.data?.token)?.id);
                        localStorage.setItem(LocalStorageKeys.accessType, JSON.stringify(role))
                        localStorage.setItem(LocalStorageKeys.clientCurrency, JSON.stringify(res?.data?.client_currency))
                        localStorage.setItem(LocalStorageKeys.is_fresh_login, true);
                        //localStorage.setItem(LocalStorageKeys.userProfileId, res?.data?.userProfile?.[0]?.id)
                        backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                        //get client
                        getClientId(role?.[0]?.role_id)

                  } else {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: t("SorryYouDontHaveAccess"),
                        });
                        backdrop.setBackDrop({ ...backdrop, open: false, message: "", });

                        RetainLocalStorage();
                        firebaseContext.deleteToken();
                        history.push(Routes.login);

                  }


            }).catch((err) => {
                  if (err?.response?.data?.status === 423) {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: err?.response?.data?.message,
                        });
                  }
                  else {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: t("SomethingWentwrong"),
                        });
                  }
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "", });

                  setDisable(false)
            });
      }

      const handleGoBack = () => {
            localStorage.removeItem(LocalStorageKeys.authToken)
            history.goBack(-1);
      }
      return (<div>
            <Container className={classes.mob_right_section_card} maxWidth="sm">
                  <TitleBarNew is_back_button title={t("Clients")} goBack={handleGoBack} />
                  <Grid container className={classes.mob_content}>
                        <Grid item xs={12} className={classes.mob_body_items}
                              sx={{ height: size?.height - 134, overflow: "overlay" }}>
                              <Box className={classes.mob_background}>
                                    <img className={classes.mob_background_image} src={screen_state?.mob_background} alt="" />
                              </Box>
                              <Box height={"16px"} />
                              <Typography className={classes.web_title}>
                                    {t("Select Client")}
                              </Typography>
                              <Box height={"16px"} />
                              <Grid>
                                    <Grid item xs={12}>
                                          <div className={classes.list}
                                                style={{
                                                      overflow: "overlay", paddingBottom: "20px",
                                                      height: size.height > 700 ? size?.height - 457 : 200,
                                                }}>
                                                {state?.map((value) => {
                                                      return (<CompanyList
                                                            data={value}
                                                            companySelect={companySelect}
                                                            seleted={client?.id} />)
                                                })}
                                          </div>
                                    </Grid>
                              </Grid>
                        </Grid>
                        <Grid item xs={12} alignSelf={"self-end"} className={classes.mob_bottom_items}>
                              <Stack direction={"column"} alignItems={"start"}>
                                    <Button className={classes.mob_set_up_new_button} disabled={disable}
                                          onClick={submit}>
                                          <Typography className={classes.mob_set_up_new_button_text}>
                                                {t("Continue")}
                                          </Typography>
                                    </Button>
                              </Stack>
                        </Grid>
                  </Grid>
            </Container>
      </div>);
};
export default withNamespaces("welcomePage")(CompanySelect)

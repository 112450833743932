import { Container, Typography, Stack, Box, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useLocation } from "react-router-dom";
import { DialogDrawer, DocumentViewer } from "../../components";
import { config } from "../../config";
import { BackdropContext } from "../../contexts";
import { GET_ANNOUNCEMENT_BY_ID } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { AlertProps, FontFamilySwitch, generateImageUrl, img_size, NetWorkCallMethods } from "../../utils";
import { AlertContext } from "../../contexts";
import useWindowDimensions from "../../utils/useWindowDimensions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTheme } from "@mui/styles";
import { withNamespaces } from "react-i18next";
import FileViewer from "react-file-viewer";
import ImageViewer from "react-simple-image-viewer";
import { withBottombar } from "../../HOCs";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#F2F5FA",
  },
  name: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary,
  },
  posted: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
  },
  title: {
    marginBottom: "8px",
  },
  dis: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
    cursor: "pointer",
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
  content: {
    padding: "16px",
    backgroundColor: "white",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 4px #00000029",
  },
  health: {
    fontSize: "0.75rem",
    color: "#98A0AC",
    fontFamily: FontFamilySwitch().bold,
    background: "#E4E8EE",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  reactQuil: {
    "& .ql-editor": {
      padding: "0px 0px 10px 0px",
    },
  },
  heading: {
    "& .ql-editor": {
      fontFamily: FontFamilySwitch().bold,
      fontSize: "1.125rem",
    },
  },
  imageCard: {
    height: "75px",
    width: "100%",
    display: 'flex'
  },
}));

const AnnouncementDetails = (props) => {
  const { t = () => false, handleTopBarState } = props;
  const classes = useStyles();
  const theme = useTheme();
  const backdrop = React.useContext(BackdropContext);
  const size = useWindowDimensions();
  const searchURL = useLocation().search;
  const Id = new URLSearchParams(searchURL).get("Id");
  const [datas, setDatas] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const [pdfViewer, setPdfViewer] = React.useState({
    bool: false,
    data: "",
  });
  const [isViewerOpen, setIsViewerOpen] = React.useState({
    bool: false,
    data: 0
  });

  const openImageViewer = (url) => {
    setPdfViewer({
      bool: true,
      data: url,
    });
  };

  const closeImageViewer = () => {
    setPdfViewer({
      bool: false,
      data: "",
    });
  };

  const getNotification = () => {
    const params = {
      query: GET_ANNOUNCEMENT_BY_ID,
      variables: {
        id: Id,
        type: "Announcement",
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        setDatas(response?.data?.data?.notifications?.[0]);

        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing Went wrong."),
        });
      });
  };
  React.useEffect(() => {
    handleTopBarState({
      text: t("Announcement")
    })
    getNotification();
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    // eslint-disable-next-line
  }, []);
  const modules = {
    toolbar: false,
  };

  const updateState = (index, close) => {
    if (close) {
      setIsViewerOpen({ ...isViewerOpen, bool: !isViewerOpen?.bool })
    }
    else {
      setIsViewerOpen({ data: index, bool: !isViewerOpen?.bool })
    }

  }
  return (
    <Container
      className={classes.root}
      maxWidth="sm"
      style={{ height: size?.height }}
    >
      {/* <TitleBar text="Announcement" goBack={() => goBackFunction()} /> */}
      <div
        style={{
          padding: "19px 16px 16px",
        }}
      >
        <div
          className={classes.content}
          style={{ height: size.height - 120, overflow: "auto" }}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            marginBottom={"10px"}
          >
            <Typography>
              <span className={classes.health}>{datas?.category}</span>
            </Typography>
            <Typography className={classes.posted}>
              {t("Posted")} {moment(new Date(datas?.created_at)).fromNow()}
            </Typography>
          </Stack>
          {datas?.description?.map((datas, index) => {
            return (
              <>
                {datas?.images && (
                  <img
                    src={generateImageUrl(datas?.images, img_size.small_rectangle) ?? ""}
                    alt=""
                    style={{
                      width: "100%",
                      height: 185,
                      borderRadius: theme.palette.borderRadius,
                      objectFit: "contain",
                      marginBottom: "10px",
                    }}
                    onClick={() => updateState(index, false)}
                  />
                )}
                {datas?.title && (
                  // <Typography className={classes.title}>
                  <ReactQuill
                    readOnly
                    theme="bubble"
                    value={datas?.title ?? ""}
                    modules={modules}
                    className={`${classes.reactQuil} ${classes.heading}`}
                  />
                  // </Typography>
                )}

                {datas?.Description && (
                  // <Typography className={classes.dis}>
                  <ReactQuill
                    readOnly
                    theme="bubble"
                    value={datas?.Description ?? ""}
                    modules={modules}
                    className={classes.reactQuil}
                  />
                  // </Typography>
                )}
                {datas?.attachment && (
                  <Box>
                    <Box height={'16px'} />
                    <Grid container spacing={1}>
                      {datas?.attachment?.map((x) => {
                        return (
                          <Grid item xs={4} sm={4} md={3} lg={2}>
                            <Box
                              className={classes.imageCard}
                              onClick={() => openImageViewer(x)}
                            >
                              <FileViewer fileType={"pdf"} filePath={x?.url} />
                            </Box>
                            <DialogDrawer
                              height={"833px"}
                              open={pdfViewer?.bool ?? false}
                              header={pdfViewer?.data?.name ?? ""}
                              component={
                                pdfViewer?.data?.url?.length > 0 ? (
                                  <DocumentViewer url={x?.url} />
                                ) : (
                                  t("No Date Found")
                                )
                              }
                              onClose={() => closeImageViewer()}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                )}
              </>
            );
          })}
        </div>
        {isViewerOpen?.bool && (
          <ImageViewer
            src={
              datas?.description?.map((x) => x?.images) ?? []
            }
            currentIndex={isViewerOpen?.data}
            onClose={() => updateState("", true)}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
            closeOnClickOutside={true}
          />
        )}
      </div>
    </Container>
  );
};

const props = {
  top_bar: "title_bar",
}


export default withBottombar(withNamespaces("announcement")(AnnouncementDetails), props);
